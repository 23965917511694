import React from "react";
import Mycard from "./commonComponents/Mycard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Axios from "../utilities/axios";
import { useParams } from "react-router";
import parse from "html-react-parser";
import { DataGrid } from "@mui/x-data-grid";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import swal from "sweetalert";
import { region } from './../utilities/region'

const Student = () => {
  const [result, setResult] = React.useState(null);
  const [rows, setRows] = React.useState([]);
  const [courseRows, setCourseRows] = React.useState([]);
  const [studentStatus, setStudentStatus] = React.useState(null);
  const [refreshTrigger, setRefreshTrigger] = React.useState(false);
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const studentStatusArr = [
    { id: 1, name: "Status 1" },
    { id: 2, name: "Status 2" },
    { id: 3, name: "Status 3" },
    { id: 4, name: "Status 4" },
  ];
  let { id } = useParams();
  React.useEffect(() => {
    Axios.get(`/student/get_single_student/${id}`)
      .then((res) => {
        let r = res && res.data && res.data.data;
        setRows(r[2]);
        setResult(r);
      
        const dataWithId = r[1].map((item, index) => ({
          ...item,
          id: index + 1  // Adding a unique id based on index
        }));
        setCourseRows(dataWithId)
        setStudentStatus(r?.[0]?.[0]?.student_level_status);
      })
      .catch((err) => {
        console.log(err);
      });
    //eslint-disable-next-line
  }, [refreshTrigger]);
  
  const student = [
    {
      iClass: "flaticon-checklist text-red",
      name: "Fee Details",
      number: `${(result && result?.[1]?.[0]?.fee_details) || 0}`,
    },
    {
      iClass: "flaticon-open-book text-magenta",
      name: "Course(s)",
      number: `${result && result[1].length}`,
    },
    {
      iClass: "flaticon-books text-mauvelous",
      name: "Association Duration",
      number: `${result && result[0][0].associated_from}`,
    },
    {
      iClass: "flaticon-script text-blue",
      name: "Test Attended",
      number: `${result && result[1].length}`,
    },
  ];
  const columns = [
    {
      field: id,
      headerName: "ID",
      width: "200",
      hide: true,
    },
    {
      field: "course_name",
      headerName: "Course Name",
      width: "200",
    },

    {
      field: "class_name",
      headerName: "Class Name",
      width: "200",
    },
    {
      field: "attendence_date",
      headerName: "Attendance Date",
      width: "200",
    },
    {
      field: "attendence",
      headerName: "Attendance",
      width: "200",
    },
  ];

  const PaymentDropdownCell = ({ value, onChange, course_id }) => (
    <Select
      value={value}
      onChange={(e) => studentStatusChange('payment_status',e.target.value,course_id)}
      size="small"
      fullWidth
    >
      <MenuItem value="Successful">Successful</MenuItem>
      <MenuItem value="Failed">Failed</MenuItem>
      <MenuItem value="Refunded">Refunded</MenuItem>
      <MenuItem value="Exempted">Exempted</MenuItem>
      <MenuItem value="Alternate arrangements made">Alternate arrangements made</MenuItem>
      <MenuItem value="Sponsored">Sponsored</MenuItem>
    </Select>
  );

  const RegistrationDropdownCell = ({ value, onChange, course_id  }) => (
    <Select
      value={value}
      size="small"
      fullWidth
      onChange={(e) => studentStatusChange('registration_status',e.target.value,course_id)}
    >
      <MenuItem value="Registered">Registered</MenuItem>
      <MenuItem value="Registration failed">Registration failed</MenuItem>
      <MenuItem value="Canceled">Canceled</MenuItem>
      <MenuItem value="Completed">Completed</MenuItem>
      <MenuItem value="Memorizer">Memorizer</MenuItem>
      <MenuItem value="Gold medalist">Gold medalist</MenuItem>
      <MenuItem value="Failed">Failed</MenuItem>
      <MenuItem value="Dropped">Dropped</MenuItem>
    </Select>
  );

  const tableColumns = [
    {
      field: "course_id",
      headerName: "Id",
      flex : 0.5
    },
    {
      field: "course_name",
      headerName: "Course Name",
      flex : 1.5
    },
    {
      field: "payment_status",
      headerName: "Payment Status",
      flex : 1,
      renderCell: (params) => (
        <PaymentDropdownCell
          value={params.value}
          onChange={(newValue) => {
            // Update the cell value in rows
            const updatedRows = courseRows.map((row) =>
               row.id === params.id
                 ? { ...row, student_payment_status: newValue } // update the specific field
                 : row
            );
            setCourseRows(updatedRows);
          }}
          course_id={params.row.course_id}
        />
      ),
    },
    {
      field: "registration_status",
      headerName: "Registration Status",
      flex : 1,
      renderCell: (params) => (
        <RegistrationDropdownCell
          value={params.value}
          onChange={(newValue) => {
            // Update the cell value in rows
            const updatedRows = rows.map((row) =>
              row.id === params.id ? { ...row, student_registration_status: newValue } : row
            );
            setCourseRows(updatedRows);
          }}
          course_id={params.row.course_id}
        />
      ),
    },
  ];

  // const studentStatusChange = async (key,val,course_id) => {
  //   if (val) {
  //     swal({
  //       title: "Are you sure?",
  //       text: "You want to change this student status",
  //       icon: "warning",
  //       buttons: true,
  //       dangerMode: false,
  //     }).then((event) => {
  //       if (event) {
  //         let data = {
  //           user_id: `${result?.[0]?.[0]?.user_id}`,
  //           student_level_status: val,
  //         };
  //         Axios.put("student/update_student_profile_status", data)
  //           .then((res) => {
  //             setStudentStatus(val);
  //           })
  //           .catch((err) => {
  //             console.log(err);
  //           });
  //       }
  //     });
  //   }
  // };

  const studentStatusChange = async (key,val,course_id) => {
    if (val) {
      swal({
        title: "Are you sure?",
        text: `You want to change ${key.replaceAll('_',' ')}`,
        icon: "warning",
        buttons: true,
        dangerMode: false,
      }).then((event) => {
        if (event) {
          let data = {
            user_id: `${result?.[0]?.[0]?.user_id}`,
            [key]: val,
            course_id : course_id
          };
          Axios.put(`student/update_${key}`, data)
            .then((res) => {
              handleRefresh();
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    }
  };

  const handleRefresh = () => {
    setRefreshTrigger(!refreshTrigger); // Toggle the state to re-run useEffect
  };

  return (
    <React.Fragment>
      {result ? (
        <div className="card height-auto">
          <div className="card-body">
            <h4 className="main-h">
              {result?.[0]?.[0]?.fullname.toUpperCase()}
            </h4>
            <section className="mg-b-20">
              <div className="row">
                <div className="col-md-8 w-topics w-t-view">
                  <Carousel responsive={responsive} infiniteLoop={true}>
                    {student.map((item, index) => (
                      <div key={index} className="carou-slider">
                        <Mycard
                          iClass={item.iClass}
                          name={item.name}
                          number={item.number}
                          key={index}
                        />
                      </div>
                    ))}
                  </Carousel>
                  <div className="card-body table-responsive">
                    <div style={{ height: 400, width: "100%" }}>
                      <DataGrid
                        rows={rows}
                        columns={columns}
                        data={result[1]}
                        pageSize={10}
                        pageSizes={[10, 20, 30, 40, 50]}
                        totalCount={result[1].length}
                        onRowClick={(e, rowData) => {
                          console.log(rowData);
                        }}
                        // components={{}}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4 w-topics w-t-view">
                  <div className="c-profile">
                    <img alt="not loaded" src={result?.[0]?.[0]?.image}></img>
                    <div className="c-content">
                      <div className="c-values">
                        {result?.[0]?.[0] &&
                          parse(result?.[0]?.[0]?.description)}
                      </div>
                    </div>
                    <div className="c-content">
                      <div>Gender</div>
                      <div className="c-values">{result?.[0]?.[0]?.gender}</div>
                    </div>
                    <div className="c-content">
                      <div>Date Of Birth</div>
                      <div className="c-values">{result?.[0]?.[0]?.dob}</div>
                    </div>
                    <div className="c-content">
                      <div>E-Mail</div>
                      <div className="c-values">
                        {result?.[0]?.[0]?.email_id}
                      </div>
                    </div>
                    <div className="c-content">
                      <div>Phone</div>
                      <div className="c-values">
                        {result?.[0]?.[0]?.phone_number}
                      </div>
                    </div>
                    <div className="c-content">
                      <div>Address</div>
                      <div className="c-values">
                        {result?.[0]?.[0]?.address}
                      </div>
                    </div>
                  </div>
                  <div className="c-profile mt-3">
                    <div className="c-content">
                      <FormControl fullWidth>
                        <InputLabel id="course-duration-select-helper-label">
                          Student Region
                        </InputLabel>
                        <Select
                          labelId="student-status-select-helper-label"
                          id="student-status-select-helper"
                          value={result?.[0]?.[0]?.region}
                          label="Student Status"
                          onChange={(e) => studentStatusChange('region',e.target.value,null)}
                          MenuProps={{
                            PaperProps: {
                              sx: {
                                maxWidth: 200,
                                whiteSpace: "normal",
                              },
                            },
                          }}
                        >
                          {region.map((name) => (
                            <MenuItem value={name.Country+'('+ name.CountryCode +')'}>
                              {name.Country +
                                '(' +
                                name.CountryCode +
                                ')'}
                            </MenuItem>
                          ))}
                          
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="card-body table-responsive">
                  <div style={{ height: 400, width: "100%" }}>
                    <br />
                    <h4>List of student courses</h4>
                      <DataGrid
                        rows={courseRows}
                        columns={tableColumns}
                        pageSize={10}
                        pageSizes={[10, 20, 30, 40, 50]}
                        totalCount={courseRows.length}
                        onRowClick={(e, rowData) => {
                          console.log(rowData);
                        }}
                      />
                  </div>
                </div>
          </div>
        </div>
      ) : (
        <div id="preloader"></div>
      )}
    </React.Fragment>
  );
};
export default Student;
