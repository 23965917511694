import { Box, FormControl, InputLabel, MenuItem, Modal, Select, TextField } from '@mui/material'
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import CloseIcon from '@mui/icons-material/Close';
import Axios from "../../utilities/axios";
import { toast } from 'react-toastify';
import React from 'react'
import { DatePicker } from '@mui/lab';
const style = {
    position: "absolute",
    top: "30%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,

    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,


};


const AddStudentEvent = ({ setModelOpen,
    modelOpen, event_rows, setRows }) => {
    const [courseList, setCourseList] = React.useState([])
    const [event_details, setEventDetails] = React.useState([])
    const [addStudnet, setAddStudent] = React.useState({
        course_id: "",
        event_id: "",
        email_id: ""
    })


    const handleClose = () => {
        setModelOpen(!modelOpen);

    }

    const callapi = () => {
        Axios.get('/courses/get_all_courses')
            .then((res) => {
                let r = res?.data?.data[0]

                setCourseList(r)
            })
            .catch((err) => {
                toast("Something went Wrong", {
                    type: "error",
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            })
    }

    React.useEffect(() => {
        callapi();
    }, [])
    const filter_events = (value) => {

        setAddStudent({ ...addStudnet, course_id: value })
        let fil_event = event_rows.filter(val => {
            return val.course_id == value
        })
        setEventDetails(fil_event)
    }

    const handleSubmit = () => {
        Axios.post('/events/add_student_to_event', addStudnet)
            .then((res) => {
                console.log(res, "res.meaasage")
                if (res?.data?.message == 'success') {
                    toast("Successfully student added to event", {
                        type: "success",
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    setRows(res?.data?.result)
                    setModelOpen(!modelOpen)

                }
                else {
                    toast(res?.data?.message, {
                        type: "error",
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                }


            })
            .catch((err) => {

                toast("Something went Wrong", {
                    type: "error",
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            })
    }

    return (
        <div> <Modal
            open={modelOpen}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="gbb"
        >
            <Box sx={style}>
                <div className="row gutters-8">

                    <h4>
                        Add User
                    </h4>

                    <span style={{
                        position: "absolute",
                        right: "11px",
                        top: "18px",
                        cursor: "pointer"
                    }}>   <CloseIcon onClick={handleClose} /></span>

                </div>


                <div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">

                                <div className="col-md-12 col-xl-12 col-lg-12 col-12 form-group">
                                    <TextField
                                        id="outlined-basic"
                                        label="Email Id *"
                                        variant="outlined"
                                        value={addStudnet?.email_id}
                                        onChange={(e) =>
                                            setAddStudent({ ...addStudnet, email_id: e.target.value })
                                        }
                                    />
                                </div>
                                <div className="col-md-12 col-xl-12 col-lg-12 col-12 form-group">
                                    <FormControl fullWidth>
                                        <InputLabel id="course-duration-select-helper-label">
                                            Course Name *
                                        </InputLabel>
                                        <Select
                                            labelId="course-duration-select-helper-label"
                                            id="course-duration-select-helper"
                                            value={addStudnet?.course_id}
                                            // disabled={type === 'view' ? true : false}
                                            label="Course Name"
                                            // disabled={status}
                                            onChange={(e) =>

                                                filter_events(e.target.value)
                                            }
                                        >
                                            <MenuItem value="">None</MenuItem>
                                            {courseList &&
                                                courseList.map((item) => (
                                                    <MenuItem value={item.id}>
                                                        {item?.course_name}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className="col-md-12 col-xl-12 col-lg-12 col-12 form-group">
                                    <FormControl fullWidth>
                                        <InputLabel id="course-duration-select-helper-label">
                                            Event Name
                                        </InputLabel>
                                        <Select
                                            labelId="course-duration-select-helper-label"
                                            id="course-duration-select-helper"
                                            value={addStudnet?.event_id}
                                            // disabled={type === 'view' ? true : false}
                                            label="event Name"
                                            // disabled={status}
                                            onChange={(e) =>
                                                setAddStudent({ ...addStudnet, event_id: e.target.value })
                                            }
                                        >
                                            <MenuItem value="">None</MenuItem>
                                            {event_details &&
                                                event_details.map((item) => (
                                                    <MenuItem value={item.event_id}>
                                                        {item?.event_name}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 form-group mg-t-8">
                            <button
                                type="button"
                                onClick={() => {
                                    handleSubmit()
                                }}
                                className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
                            >
                                {/* {!clicked ? "Add Course" : "Please wait.."} */}
                                Add
                            </button>
                            {/* <button
                                type="reset"
                                onClick={() => {
                                    setAddStudent({
                                        course_id: "",
                                        event_id: "",
                                        email_id: ""

                                    });
                                }}
                                className="btn-fill-lg bg-blue-dark btn-hover-yellow"
                            >
                                RESET
                            </button> */}
                        </div></div>
                </div>
            </Box>
        </Modal>
        </div>
    )
}

export default AddStudentEvent