import React from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Axios from "../../utilities/axios";
import { useHistory } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { uploadFile } from "react-s3";
import { toast } from "react-toastify";
import moment from "moment-timezone";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import DatePicker from "@mui/lab/DatePicker";
// import DateRangePicker from "@mui/lab/DateRangePicker";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";

import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { formatDate, shortFormatDate } from "../../utilities/convert";

const AttendanceReport = () => {
  // courses / get_all_courses_for_teacher / 1633417307942 / 10989368 / 12139214;
  const [rows, setRows] = React.useState([]);
  const [originalRows, setOriginalRows] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [attendanceReportData, setAttendanceReportData] = React.useState(null);
  const [courses, setCourses] = React.useState([]);
  const [classes, setClasses] = React.useState([]);
  const [selectedClass, setSelectedClass] = React.useState("");
  const [selectedCourse, setSelectedCourse] = React.useState("");
  // const [date, setDate] = React.useState([null, null]);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [selected, setSelected] = React.useState([]);

  React.useEffect(() => {
    CallApi();
    //eslint-disable-next-line
  }, [selectedCourse]);

  const CallApi = () => {
    const uid = localStorage.getItem("uid");
    let reqBody = {
      course_admin_id: uid,
    };
    if (selectedCourse) {
      reqBody["course_id"] = selectedCourse;
    }
    Axios.post(`courses/get_courses_students`, reqBody)
      .then((res) => {
        if(selectedCourse){
          const classes = Array.isArray(res.data.classes) ? res.data.classes : [];
          setClasses(classes);
        }else{
          const courses = Array.isArray(res.data.courses) ? res.data.courses : [];
          setCourses(courses);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const columnArr = [
    { field: "id", headerName: "Student Id", width: 120, hide: true },
    {
      field: "id",
      headerName: "Sr.No",
      width: 120,
      hide: true
    },
    { field: "student_name", headerName: "Student Name", width: 260 },
    {
      field: "attendance_summary",
      headerName: "Total",
      width: 210,
      type: "status",
      renderCell: totalAttendanceSummary,
    },
  ];
  let [columns, setColumns] = React.useState(columnArr);

  const debounce = (func, wait) => {
    let timeout;
    return function(...args) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), wait);
    };
  };

  const searchStudentAttendance = (searchVal) => {
    if(searchVal){
      let filterResult = originalRows.filter(student =>
        student.student_name.toLowerCase().includes(searchVal.toLowerCase())
      );
      setRows(filterResult);
    }else{
      setRows(originalRows);
    }
  };

  const debouncedFetch = React.useCallback(debounce(searchStudentAttendance, 500), [originalRows]);
  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    debouncedFetch(value);
  };


  const viewAttendanceReport = () => {
    viewAttendanceReportList(selectedCourse, selectedClass, startDate, endDate);
  };

  const viewAttendanceReportList = (courseId, classId, startDate, endDate) => {
    if (!selectedCourse) {
      toast("Please select course", {
        type: "error",
        theme: "dark",
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
      });
      return;
    }
    if (!classId) {
      toast("Please select Class", {
        type: "error",
        theme: "dark",
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
      });
      return;
    }
    if (!startDate || !endDate) {
      toast("Please select both start and end dates", {
        type: "error",
        theme: "dark",
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
      });
      return;
    }
    const uid = localStorage.getItem("uid");
    Axios.post(`courses/attendance_report`, {
      course_admin_id: uid,
      course_id: courseId,
      class_id: classId,
      start_date: formatDate(startDate),
      end_date: formatDate(endDate),
    })
      .then((res) => {
        const reportData = res?.data?.data;
        setAttendanceReportData(reportData);

        let students = reportData?.students || [];
        if (!Array.isArray(students)) {
          students = [];
        }
        // Extract unique attendance dates
        const uniqueDates = [...new Set(students.flatMap(student => student.attendance_records.map(record => record.attendance_date)))];

        // Create dynamic columns for each unique date
        const dynamicColumns = uniqueDates.map(date => ({
          headerName: shortFormatDate(date),
          field: date, // Use the date as the field name
          width: 140,
          type: "statusval",
        }));

        // Combine static and dynamic columns
        const staticColumns = [
          {
            field: "id",
            headerName: "Sr.No",
            width: 120,
            hide: true
          },
          { field: "student_name", headerName: "Student Name", width: 260 },
          {
            field: "attendance_summary",
            headerName: "Total",
            width: 210,
            type: "status",
            renderCell: totalAttendanceSummary,
          },
        ];

        setColumns([...staticColumns, ...dynamicColumns]);
        
        // Format rows to include attendance statuses for each date
        const rowsWithId = students.map((student, index) => {
          const row = { id: index + 1, ...student };
          student.attendance_records.forEach(record => {
            row[record.attendance_date] = record.attendance_status;
          });
          return row;
        });
        setRows(rowsWithId.length > 0 ? rowsWithId : []);
        setOriginalRows(rowsWithId.length > 0 ? rowsWithId : []);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const viewReport = () => {
    viewAttendanceReport(selectedCourse);
  };

  const dynamicHeight = rows.length == 1 ? rows.length * 230 : (rows.length == 2 ? rows.length * 130 : rows.length * 110);
  const minHeight = 350;
  const maxHeight = 600;  

  return (
    <div>
      <div className="card height-auto">
        <div className="card-body">
          <div className="heading-layout1 row">
            <div className="item-title col-md-6 mb-3">
              <h4>Attendance Report</h4>
            </div>

            <Box
              className="item-title col-md-6 mb-3"
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <button
                type="submit"
                onClick={viewReport}
                className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
              >
                View Report
              </button>
            </Box>
            <div className="item-title col-md-4">
              <FormControl fullWidth>
                <InputLabel id="course-duration-select-helper-label">
                  Select Course
                </InputLabel>
                <Select
                  labelId="course-duration-select-helper-label"
                  id="course-duration-select-helper"
                  value={selectedCourse}
                  label="Course Duration"
                  onChange={(e) => {
                    setSelectedClass("");
                    setSelectedCourse(e.target.value);
                  }}
                >
                  {courses?.map((item) => (
                    <MenuItem key={item?.course_id}  value={`${item?.course_id}`}>
                      {item?.course_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="item-title col-md-4">
              <FormControl fullWidth>
                <InputLabel id="course-duration-select-helper-label">
                  Select Class
                </InputLabel>
                <Select
                  labelId="course-duration-select-helper-label"
                  id="course-duration-select-helper"
                  value={selectedClass}
                  label="Course Duration"
                  onChange={(e) => setSelectedClass(e.target.value)}
                >
                  {classes?.map((item) => (
                    <MenuItem key={item?.class_id} value={`${item?.class_id}`}>
                      {item?.class_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {/* Date Selection for start date*/}
            <div className="item-title col-md-2">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Select Start Date"
                  value={startDate}
                  // mask="__-__-____"
                  mask="dd-MM-yyyy"
                  inputFormat="dd-MMM-yyyy"
                  // disablePast={true}
                  onChange={(newValue) => {
                    //   let mm = moment(newValue).format("DD-MMM-YYYY");
                    setStartDate(moment(newValue).format("DD-MMM-YYYY"));
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            {/* Date Selection for end date */}
            <div className="item-title col-md-2">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Select End Date"
                  value={endDate}
                  // mask="__-__-____"
                  mask="dd-MM-yyyy"
                  inputFormat="dd-MMM-yyyy"
                  // disablePast={true}
                  onChange={(newValue) => {
                    //   let mm = moment(newValue).format("DD-MMM-YYYY");
                    setEndDate(moment(newValue).format("DD-MMM-YYYY"));
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
          </div>
          {attendanceReportData && 
          <div className="heading-layout1 row mt-5">
            <div className="col-4">
            <TextField
                  id="outlined-basic"
                  label="Search..."
                  variant="outlined"
                  type="text"
                  value={searchTerm}
                  onChange={handleSearch}
                />
            </div>
            <div className="col-8 d-flex justify-content-between">
            <div className="item-detail d-flex">
              <div className="item-key">Total Students :&nbsp;</div>
              <div className="item-val font-weight-bold">
                {attendanceReportData?.total_student_count}
              </div>
            </div>

            <div className="item-detail d-flex">
              <div className="item-key">Present :&nbsp;</div>
              <div className="item-val font-weight-bold">
                {attendanceReportData?.statistics?.present_student_percentage}
              </div>
            </div>

            <div className="item-detail d-flex">
              <div className="item-key">Absent :&nbsp;</div>
              <div className="item-val font-weight-bold">
                {attendanceReportData?.statistics?.absent_student_percentage}
              </div>
            </div>

            <div className="item-detail d-flex">
              <div className="item-key">Moved :&nbsp;</div>
              <div className="item-val font-weight-bold">
                {attendanceReportData?.statistics?.moved_student_percentage}
              </div>
            </div>

            <div className="item-detail d-flex">
              <div className="item-key">Drop Out :&nbsp;</div>
              <div className="item-val font-weight-bold">
                {attendanceReportData?.statistics?.drop_out_student_percentage}
              </div>
            </div>
            </div>
          </div>}
          {rows.length > 0 ? (
            <div className="table-responsive" style={{
              height: `${dynamicHeight}px`,
              maxHeight: `${maxHeight}px`,
              overflowY: 'auto' // ensure scroll if content overflows maxHeight
            }}>
              <DataGrid
                components={{
                  Toolbar: GridToolbar,
                }}
                rows={rows}
                columns={columns}
                pageSize={8}  
                rowsPerPageOptions={[8]}
              />
            </div>
          ):(
            <div className="d-flex justify-content-center align-items-center mt-5 pt-5">
              No Records
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AttendanceReport;


const totalAttendanceSummary = (params) => {
  return (
    <div className="item-detail d-flex">
      <div className="item">{params?.row?.attendance_summary}&nbsp;</div>
      <div className="item">
        (          {params?.row?.attendance_percentage})
      </div>
    </div>
  );
};