import React from 'react';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Axios from "../../utilities/axios";
import { useHistory } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { uploadFile } from "react-s3";
import { toast } from "react-toastify";
import moment from "moment-timezone";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import DatePicker from "@mui/lab/DatePicker";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from "@mui/material/TextField";
import { formatDate } from "../../utilities/convert";
const MarkAttendance = () => {
  // courses / get_all_courses_for_teacher / 1633417307942 / 10989368 / 12139214;
  const [rows, setRows] = React.useState([]);
  const [originalRows, setOriginalRows] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [courses, setCourses] = React.useState([]);
  const [classes, setClasses] = React.useState([]);
  const [selectedClass, setSelectedClass] = React.useState("");
  const [selectedCourse, setSelectedCourse] = React.useState("");
  const [attendanceStatus, setAttendanceStatus] = React.useState(null);
  const [selected, setSelected] = React.useState([]);
  const [date, setDate] = React.useState(new Date());
  // const [pdfName, setPdfName] = React.useState("");
  // const [selectedFile, setSelectedFile] = React.useState(null);
  React.useEffect(() => {
    CallApi();
  }, [selectedCourse]);

  const CallApi = () => {
    const uid = localStorage.getItem("uid");
    let reqBody = {
      course_admin_id: uid,
    };
    if (selectedCourse) {
      reqBody["course_id"] = selectedCourse;
    }
    Axios.post(`courses/get_courses_students`, reqBody)
      .then((res) => {
        if(selectedCourse){
          const classes = Array.isArray(res.data.classes) ? res.data.classes : [];
          setClasses(classes);
        }else{
          const courses = Array.isArray(res.data.courses) ? res.data.courses : [];
          setCourses(courses);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const columns = [
    { field: "id", headerName: "Sr No.", flex: 0.5, hide: true},
    { field: "student_id", headerName: "Student Id", flex: 1, hide: true,},
    { field: "student_name", headerName: "Student Name", flex: 2 },
    {
      field: "attendance_status",
      headerName: "Attendance Status",
      flex: 1
    },
    // {
    //   field: "attendance_status",
    //   headerName: "Attendance Status",
    //   flex: 1,
    //   type: "status",
    //   renderCell: (params) => (
    //     <AttendanceStatusField key={params.id} params={params} data={rows} />
    //   ),
    // },
    {
      field: "student_attendance_id",
      headerName: "Student Attendance ID",
      flex: 1,
      hide: true,
    },
    {
      field: "attendance_id",
      headerName: "Attendance ID",
      flex: 1,
      hide: true,
    },
  ];

  const handleAttendance = () => {
    if (selected.length == 0) {
      toast("Please select students", {
        type: "error",
        theme: "dark",
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
      });
      return;
    } 
    if (!attendanceStatus) {
      toast("Please select attendance status", {
        type: "error",
        theme: "dark",
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
      });
      return;
    }

    for (let i = 0; i < selected.length; i++) {
      selected[i].attendance_status = attendanceStatus;
      delete selected[i].id;
      delete selected[i].student_name;
    }

    const uid = localStorage.getItem("uid");
    let data = {
      course_admin_id: uid,
      course_id: selectedCourse,
      class_id: selectedClass,
      attendance_date: formatDate(date),
      students: selected,
    };
    Axios.post("courses/update_students_attendance", data)
      .then((res) => {
        if (res.data.status === 200) {
          toast.success("Attendence Updated", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setRows([]);
          setOriginalRows([]);
          // setCourses([]);
          // setClasses([]);
          setDate(new Date());
          setSearchTerm('');

        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const debounce = (func, wait) => {
    let timeout;
    return function(...args) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), wait);
    };
  };

  const searchStudentAttendance = (searchVal) => {
    if(searchVal){
      let filterResult = originalRows.filter(student =>
        student.student_name.toLowerCase().includes(searchVal.toLowerCase())
      );
      setRows(filterResult);
    }else{
      setRows(originalRows);
    }
  };

  const debouncedFetch = React.useCallback(debounce(searchStudentAttendance, 500), [originalRows]);
  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    debouncedFetch(value);
  };

  const showStudentAttendance = () => {
    showStudentAttendanceList(selectedCourse, selectedClass, date);
  };

  const showStudentAttendanceList = (courseId, classId, dateString) => {
    if (!courseId) {
      toast("Please select course", {
        type: "error",
        theme: "dark",
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
      });
      return;
    }
    if (!classId) {
      toast("Please select Class", {
        type: "error",
        theme: "dark",
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
      });
      return;
    }
    if (!dateString) {
      toast("Please select Date range", {
        type: "error",
        theme: "dark",
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
      });
      return;
    }
    const uid = localStorage.getItem("uid");
    let obj = {
      course_admin_id: uid,
      course_id: courseId,
      class_id: classId,
      attendance_date: formatDate(dateString),
    };
    Axios.post(`courses/get_students_attendence`,obj)
      .then((res) => {
        let arr = res?.data?.data|| [];
        const rowsWithId = arr.map((student, index) => ({
          ...student,
          id: index + 1,
        }));

        setRows(rowsWithId);
        setOriginalRows(rowsWithId);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  
  const dynamicHeight = rows.length == 1 ? rows.length * 230 : (rows.length == 2 ? rows.length * 130 : rows.length * 110);
  const minHeight = 350;
  const maxHeight = 600;

  return (
    <div>
      <div className="card height-auto">
        <div className="card-body">
          <div className="heading-layout1 row">
            <div className="item-title col-md-12">
              <h4>Mark Attendance</h4>
            </div>
            <div className="item-title col-md-3">
              <FormControl fullWidth>
                <InputLabel id="course-duration-select-helper-label">
                  Select Course
                </InputLabel>
                <Select
                  labelId="course-duration-select-helper-label"
                  id="course-duration-select-helper"
                  value={selectedCourse}
                  label="Course Duration"
                  onChange={(e) => setSelectedCourse(e.target.value)}
                >
                  {courses?.map((item) => (
                    <MenuItem key={item?.course_id} value={`${item?.course_id}`}>
                      {item?.course_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="item-title col-md-3">
              <FormControl fullWidth>
                <InputLabel id="course-duration-select-helper-label">
                  Select Class
                </InputLabel>
                <Select
                  labelId="course-duration-select-helper-label"
                  id="course-duration-select-helper"
                  value={selectedClass}
                  label="Course Duration"
                  onChange={(e) => setSelectedClass(e.target.value)}
                >
                  {classes?.map((item) => (
                    <MenuItem key={item?.class_id} value={`${item?.class_id}`}>
                      {item?.class_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="item-title col-md-3">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Select Date"
                value={date}
                onChange={(newValue) => setDate(newValue)}
                maxDate={new Date()}
                inputFormat="dd-MMM-yyyy" // Format is handled differently
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            </div>
            <div className="item-title col-md-3">
              <button
                type="submit"
                onClick={showStudentAttendance}
                className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
              >
                Show Student Attendance
              </button>
            </div>
          </div>
          {originalRows.length > 0 && (
            <div className="heading-layout1 mt-4">
              <div className="item-title d-flex align-items-center">
                <h4 className="mb-0">Attendance</h4>
              </div>
              <div className="item-field col-4">
                <TextField
                  id="outlined-basic"
                  label="Search..."
                  variant="outlined"
                  type="text"
                  value={searchTerm}
                  onChange={handleSearch}
                />
              </div>
            </div>
          )}
          {rows.length > 0 ? (
            <div className="table-responsive" style={{ 
              height: `${dynamicHeight}px`,
              maxHeight: `${maxHeight}px`,
              overflowY: 'auto' // ensure scroll if content overflows maxHeight
             }}>
              <DataGrid
                components={{
                  Toolbar: GridToolbar,
                }}
                rows={rows}
                columns={columns}
                checkboxSelection
                selected={selected}
                setSelected={setSelected}
                onSelectionModelChange={(ids) => {
                  const selectedIDs = new Set(ids);
                  const selectedRows = rows.filter((row) =>
                    selectedIDs.has(row.id)
                  );
                  setSelected(selectedRows);
                }}
                // pageSize={10}
                // rowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
              />
            </div>
          ) : (
            <div className="d-flex justify-content-center align-items-center mt-5 pt-5">
              No Records
            </div>
          )}
        </div>
      </div>
      {rows.length > 0 && (<div className="row">
        <div className="item-title col-md-3">
          <FormControl fullWidth>
            <InputLabel>
              Attendance Status
            </InputLabel>
            <Select
              labelId="attendance-status-select-helper-label"
              id="attendance-status-select-helper"
              value={attendanceStatus}
              label="attendance Status"
              onChange={(e) => setAttendanceStatus(e.target.value)}
            >
              <MenuItem key="present" value="present">Present</MenuItem>
              <MenuItem key="absent" value="absent">Absent</MenuItem>
              <MenuItem key="moved" value="moved">Moved</MenuItem>
              <MenuItem key="drop-out" value="drop-out">Drop Out</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="col-md-3">
          <button
            type="submit"
            onClick={handleAttendance}
            className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
          >
            Submit Attendance
          </button>
        </div>
      </div>
      )}
    </div>
  );
};

export default MarkAttendance;

const AttendanceStatusField = ({ params, data }) => {
  const [selectStudentStatus, setSelectStudentStatus] = React.useState(
    params?.row?.attendance_status
  );
  const studentAttendanceArr = [
    {
      id: 1,
      title: "Present",
      value: "present",
    },
    {
      id: 2,
      title: "Absent",
      value: "absent",
    },
    {
      id: 3,
      title: "Moved",
      value: "moved",
    },
    {
      id: 4,
      title: "Drop Out",
      value: "drop-out",
    },
  ];

  const updateAttendanceStatus = (newStatus) => {
    setSelectStudentStatus(newStatus);
    data.map((row) => {
      if (row.student_id === params.row.student_id) {
        row.attendance_status = newStatus;
        return row;
      } else {
        return row;
      }
    });
  };

  return (
    <>
      <FormControl fullWidth>
        <Select
          labelId="course-duration-select-helper-label"
          id="course-duration-select-helper"
          value={params?.row?.attendance_status}
          label="Status"
          onChange={(e) => updateAttendanceStatus(e.target.value)}
        >
          {studentAttendanceArr?.map((item) => (
            <MenuItem key={item?.value} value={item?.value}>{item?.title}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};
