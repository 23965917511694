import React from "react";
// import { useHistory } from "react-router";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Axios from "../utilities/axios";

const EventSlotBooking = () => {
  const ticketsColumns = [
    { field: "id", headerName: "ID", width: 150, hide: true },
    { field: "name", headerName: "Name", width: 180 },
    {
      field: "email",
      headerName: "Email",
      width: 180,
    },
    {
      field: "phone",
      headerName: "Mobile Number",
      width: 180,
    },
    {
      field: "course_type",
      headerName: "Course Type",
      width: 150,
    },
    {
      field: "registration_code",
      headerName: "Registration Code",
      width: 180,
    },
    {
      field: "created_at",
      headerName: "Date of payment",
      width: 200,
    },
    {
      field: "adult",
      headerName: "Adult",
      width: 130,
    },
    {
      field: "child",
      headerName: "Child",
      width: 130,
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 150,
    },
    {
      field: "payment_id",
      headerName: "Payment Id",
      width: 185,
    },
    {
      field: "status",
      headerName: "Payment Status",
      width: 190,
      renderCell: RenderDetailsButton,
      disableClickEventBubbling: true,
    },
  ];

  const [ticketrows, setticketRows] = React.useState([]);

  React.useEffect(() => {
    let url1 = "payments/get_ticket_payment_details";
    Axios.get(url1)
      .then((res) => {
        console.log(res, "resssssss");
        let row = res?.data?.data;
        setticketRows(row);
      })
      .catch((err) => {
        console.log(err);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="card height-auto">
        {ticketrows ? (
          <div className="card-body">
            {/* <div className="heading-layout1">
            <div className="item-title">
              <h4>All Payments Data</h4>
            </div>
          </div> */}
            <div
              className="table-responsive"
              style={{ height: 650, width: "100%" }}
            >
              <DataGrid
                components={{
                  Toolbar: GridToolbar,
                }}
                rows={ticketrows}
                columns={ticketsColumns}
                pageSize={10}
                rowsPerPageOptions={[10]}
              />
            </div>
          </div>
        ) : (
          <div id="preloader"></div>
        )}
      </div>
    </>
  );
};
export default EventSlotBooking;
const RenderDetailsButton = (params) => {
  console.log(params, "paramsssss");
  return (
    <center>
      <button
        className={
          params?.row?.status === "success"
            ? "btn btn-success"
            : "btn btn-danger"
        }
        disabled={true}
      >
        {params?.row?.status}
      </button>
    </center>
  );
};
