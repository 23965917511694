import React, { useContext, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import Footer from "../components/Footer";
import Loginscreen from "../components/Loginscreen";
import Addcourse from "../components/Addcourse";
import Updating from "../components/Updating";
import Home from "../components/Home";
import Profile from "../components/Profile";
import Editprofile from "../components/Editprofile";
import Courseadmins from "../components/Courseadmins";
import Viewallcourses from "../components/Viewallcourses";
import Breadcubs from "./../components/commonComponents/Breadcubs";
import Person from "./../components/Person";
import Student from "./../components/Student";
import Schedule from "./../components/Schedule.js";
import Payment from "./../components/Payment.js";
import Cateachers from "./../components/CourseadminComps/Cateachers";
import Somelist from "../components/Somelist";
import Payments from "../components/Payments";
import Castudents from "../components/CourseadminComps/Castudents";
import { authContext } from "../context/AuthContext";
import { AUTH_TYPES } from "./../utilities/authTypes";
import ManageCourses from "./../components/Managecourse/ManageCourses";
import Cacourses from "../components/CourseadminComps/Cacourses";
import Viewcourse from "./../components/Viewcourse";
import Signup from "../components/Signup";
import Forget from "../components/Forget.js";
import Tdashboard from "../components/Teachercomps/Tdashboard";
import Attendence from "../components/Teachercomps/Attendence";
import Studentlist from "../components/Teachercomps/Studentlist";
import Teacherslist from "../components/Teachercomps/Teacherslist.js";
import { Redirect } from "react-router";
import Mycourses from "../components/Teachercomps/Mycourses";
import Homeworks from "./../components/Teachercomps/Homeworks";
import Changepassword from "./../components/commonComponents/Changepassword";
import Viewattendence from "../components/Teachercomps/Viewattendance";
import Events from "../components/Events/Events";
import EventSlotBooking from "../components/EventSlotBooking";
import Labels from "../components/Labels";
import MarkAttendance from "../components/Attendance/MarkAttendance";
import AttendanceReport from "../components/Attendance/AttendanceReport";

const Routes = ({ setIsChanged, isChanged }) => {
  let { authState, dispatch } = useContext(authContext);

  useEffect(() => {
    let user = localStorage.getItem("token");
    if (user) {
      dispatch({ type: AUTH_TYPES.LOGIN_CHECK, payload: true });
    } else {
      dispatch({ type: AUTH_TYPES.LOGIN_CHECK, payload: false });
    }
    //eslint-disable-next-line
  }, []);

  return (
    <Switch>
      <>
        {authState.isAuthenticated ? (
          <AdminRoutes setIsChanged={setIsChanged} isChanged={isChanged} />
        ) : (
          <LoginRoutes setIsChanged={setIsChanged} isChanged={isChanged} />
        )}
      </>
    </Switch>
  );
};

export default Routes;

export const LoginRoutes = ({ setIsChanged, isChanged }) => {
  return (
    <>
      <Route path="/signup/">
        <Signup />
      </Route>
      <Route path="/signup" exact>
        <Signup />
      </Route>
      <Route path="/" exact>
        <Redirect to="/login" />
      </Route>
      <Route path="/login" exact>
        <Loginscreen setIsChanged={setIsChanged} isChanged={isChanged} />
      </Route>
      <Route path="/forgotpassword" exact>
        <Forget />
      </Route>
    </>
  );
};
export const AdminRoutes = ({ setIsChanged, isChanged }) => {
  return (
    <React.Fragment>
      {/* Account Admin Routes */}
      <Route path="/Account Admin Dashboard" exact>
        <Breadcubs />
        <Home />
      </Route>
      <Route path="/Course admins" exact>
        <Breadcubs />
        <Courseadmins />
      </Route>
      <Route path="/teachers" exact>
        <Breadcubs />
        <Somelist a="Associated From" />
      </Route>
      <Route path="/courseadmin/:id" exact>
        <Breadcubs pre="course admins" />
        <Person who="courseadmin" />
      </Route>
      <Route path="/teacher/:id" exact>
        <Breadcubs pre="teachers" />
        <Person who="teacher" />
      </Route>
      <Route path="/students" exact>
        <Breadcubs />
        <Somelist a="Date of birth" />
      </Route>
      <Route path="/student/:id" exact>
        <Breadcubs pre="students" />
        <Student />
      </Route>
      <Route path="/profile" exact>
        <Breadcubs />
        <Profile />
      </Route>
      <Route path="/editprofile" exact>
        <Breadcubs pre="profile" />
        <Editprofile setIsChanged={setIsChanged} isChanged={isChanged} />
      </Route>
      <Route path="/courses" exact>
        <Breadcubs />
        <Viewallcourses />
      </Route>
      <Route path="/course/:id" exact>
        <Breadcubs pre="courses" />
        <Viewcourse />
      </Route>
      <Route path="/payments" exact>
        <Breadcubs />
        <Payments />
      </Route>
      <Route path="/eventtickets" exact>
        <Breadcubs />
        <EventSlotBooking />
      </Route>
      <Route path="/queryEmails" exact>
        <Breadcubs />
        <Labels />
      </Route>
      <Route path="/addcourse" exact>
        <Breadcubs />
        <Addcourse />
      </Route>
      <Route path="/updating" exact>
        <Breadcubs />
        <Updating />
      </Route>
      <Route path="/editcourse/:id" exact>
        <Breadcubs pre="courses" />
        <Addcourse />
      </Route>
      <Route path="/viewcourse" exact>
        <Breadcubs />
        <Viewcourse />
      </Route>
      {/* Account Admin Routes  End here*/}
      {/* Course Admin Routes  Start here*/}
      <Route path="/Course Admin Dashboard" exact>
        <Breadcubs />
        <Home />
      </Route>
      <Route path="/Course Association" exact>
        <Breadcubs />
        <Cateachers />
      </Route>
      <Route path="/my courses" exact>
        <Breadcubs />
        <Cacourses />
      </Route>
      <Route path="/single course/:id" exact>
        <Breadcubs pre="my courses" />
        <Viewcourse />
      </Route>
      <Route path="/manage/:id" exact>
        <Breadcubs pre="my courses" />
        <ManageCourses />
      </Route>
      <Route path="/edit/:id" exact>
        <Breadcubs pre="my courses" />
        <ManageCourses />
      </Route>
      <Route path="/All Students" exact>
        <Breadcubs />
        <Castudents />
      </Route>
      <Route path="/Single Student/:id" exact>
        <Breadcubs pre="All Students" />
        <Student />
      </Route>
      <Route path="/Single Teacher/:id" exact>
        <Breadcubs pre="Teachers List" />
        <Person who="teacher" />
      </Route>
      <Route path="/payment" exact>
        <Breadcubs />
        <Payment />
      </Route>
      <Route path="/Teacher Dashboard" exact>
        <Breadcubs />
        <Tdashboard />
      </Route>
      <Route path="/schedule" exact>
        <Breadcubs />
        <Schedule />
      </Route>
      <Route path="/student List" exact>
        <Breadcubs />
        <Studentlist />
      </Route>
      <Route path="/Student Profile/:id" exact>
        <Breadcubs pre="student List" />
        <Student />
      </Route>
      <Route path="/Teachers List" exact>
        <Breadcubs />
        <Teacherslist />
      </Route>
      <Route path="/attendance" exact>
        <Breadcubs />
        <Attendence />
      </Route>
      <Route path="/View Attendance" exact>
        <Breadcubs />
        <Viewattendence />
      </Route>
      <Route path="/All Courses" exact>
        <Breadcubs />
        <Mycourses />
      </Route>
      <Route path="/homeworks" exact>
        <Breadcubs />
        <Homeworks />
      </Route>
      <Route path="/viewcourse/:id" exact>
        <Breadcubs pre="All Courses" />
        <Viewcourse />
      </Route>
      <Route path="/changepassword" exact>
        <Breadcubs />
        <Changepassword />
      </Route>
      <Route path="/events" exact>
        <Breadcubs />
        <Events />
      </Route>
      <Route path="/mark-attendance" exact>
        <Breadcubs />
        <MarkAttendance />
      </Route>
      <Route path="/attendance-report" exact>
        <Breadcubs />
        <AttendanceReport />
      </Route>
      <Footer />
    </React.Fragment>
  );
};
