import React from "react";
import TextField from "@mui/material/TextField";

const Filtersearch = ({ dummy, setRows }) => {
  const [value, setValue] = React.useState("");
  React.useEffect(() => {
    let a = dummy?.filter((row) =>
      JSON.stringify(row).toLowerCase().match(value.toLowerCase())
    );
    setRows(a.length > 0 ? a : []);
    //eslint-disable-next-line
  }, [value]);
  return (

       <TextField
         id="outlined-basic"
         label="Search..."
         variant="outlined"
         type="text"
         value={value}
         onChange={(e) => setValue(e.target.value)}
       />
  );
};

export default Filtersearch;
