import React, { useState } from 'react'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Axios from '../utilities/axios'

import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { toast } from 'react-toastify'
import { CompressOutlined } from '@mui/icons-material'
let paymentExcelFile
const Updating = () => {
  const [rowss, setRowss] = useState([])
  const [details, setDetails] = useState({
    email_id: '',
    user_id: '',
    course_id: '',
    class_id: '',
    courseadmin_id: '',
  })
  const [details1, setDetails1] = useState({
    user_id: '',
    user_id1: '',
    preference_value: '',
    preference_name: "Language",
    flag: 1

  })
  const [courseDetails, setCourseDetails] = useState([])
  const [userExist, setUserExist] = useState(false)
  const [user, setUser] = useState('')
  const [courses, setCourses] = useState([])
  const [classes, setClasses] = useState([])

  const [paymentFile, setPaymentFile] = useState(false)
  const onSearch = () => {
    if (details.email_id === '') {
      toast('Please enter email id', {
        type: 'warning',
        theme: 'dark',
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
      })
    } else {
      console.log(details, 'details')
      const data = {
        email_id: details.email_id,
      }
      Axios.post('/update_details/get_student_details_by_email', data)
        .then((res) => {
          if (res?.data?.data.length == 0) {
            toast('Invalid Email id', {
              type: 'warning',
              theme: 'dark',
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: false,
            })
            setRowss(res?.data?.data)
          } else {
            let r = res?.data?.data

            setRowss(r)
            setDetails({
              ...details,
              user_id: r[0].user_id,
              courseadmin_id: localStorage.getItem('uid'),
            })
            setCourseDetails(res?.data?.course_details)
            let c = res?.data?.course_details
            let c1 = []
            console.log(c)
            c.map((item) => {
              if (
                !c1.includes(item.course_name) &&
                item.courseadmin_id == localStorage.getItem('uid')
              ) {
                c1.push(item.course_name)
              }
            })
            setCourses(c1)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  const onSearch1 = () => {
    if (details.email_id === '') {
      toast('Please enter email id', {
        type: 'warning',
        theme: 'dark',
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
      })
    } else {
      console.log(details, 'details')
      const data = {
        email_id: details.email_id,
      }
      Axios.post('/update_details/get_single_student_details_by_email', data)
        .then((res) => {
          if (res?.data?.data.length == 0) {
            toast('Invalid Email id', {
              type: 'warning',
              theme: 'dark',
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: false,
            })
            setUserExist(false)
          } else {
            console.log("nnn", res?.data?.data[0].preference_value)
            setUserExist(true)
            setUser(res?.data?.data[0].preference_value)
            setDetails1((prev) => ({
              ...prev,
              user_id: res?.data?.data[0].user_id,
              user_id1: localStorage.getItem('uid'),
              preference_value: user
            }));
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
  const onSelectCourse = (course) => {
    const filterCourse = courseDetails.filter(
      (item) => item.course_name === course,
    )
    console.log(filterCourse, 'filterCourse')
    setDetails({ ...details, course_id: filterCourse[0].course_id })
    let course_classes = []
    filterCourse.map((item) => {
      course_classes.push(item.class_name)
    })
    setClasses(course_classes)
  }
  const onSelectClass = (cls_name) => {
    const filterClass = courseDetails.filter(
      (item) =>
        item.class_name === cls_name && item.course_id === details.course_id,
    )
    setDetails({ ...details, class_id: filterClass[0].class_id })
  }
  const onClickUpdate = () => {
    if (details.course_id === '') {
      toast('Please select course', {
        type: 'warning',
        theme: 'dark',
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
      })
    } else {
      console.log(details, 'details')
      Axios.post('/update_details/update_course_to_student', details)
        .then((res) => {
          console.log(res?.data?.data, 'res')

          if (res?.data?.type === 'success') {
            let r = res?.data?.data
            setRowss(r)
            setDetails({ ...details, class_id: '' })
            toast(res?.data?.message, {
              type: 'success',
              theme: 'dark',
              autoClose: 2000,
            })
          } else {
            setDetails({ ...details, course_id: '', class_id: '' })
            setDetails({ ...details, class_id: '' })
            toast(res?.data?.message, {
              type: 'warning',
              theme: 'dark',
              autoClose: 2000,
            })
          }
        })
        .catch((err) => {
          console.log(err)
          toast('Something went wrong', {
            type: 'error',
            theme: 'dark',
            autoClose: 2000,
          })
        })
    }
  }


  const handleChangePreferenece1 = (e) => {
    setUser(e.target.value)


    setDetails1((prev) => ({
      ...prev,
      preference_value: e.target.value,
      preference_name: "Language",
    }));
  }


  const onClickUpdate1 = () => {

    Axios.post('/user_details/update_user_preference_admin', details1)
      .then((res) => {
        console.log(res?.data, 'resss')
        var alert_msg = 'Language has been Updated..'
        var type = 'success'

        toast(alert_msg, {
          type: `${type}`,
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
          // type: type,
        })

      })
      .catch((err) => {
        console.log(err)
        toast('Something went wrong', {
          type: 'error',
          theme: 'dark',
          autoClose: 2000,
        })
      })

  }




  const handleSubmitFile = async () => {
    let fileReader = new FileReader()
    fileReader.readAsBinaryString(paymentExcelFile)
    fileReader.onload = (event) => {
      let data = event.target.result
      let workbook = window.XLSX.read(data, { type: 'binary' })
      console.log(workbook)

      let finaldata = workbook.SheetNames.map((sheet) => {
        let rowObject = window.XLSX.utils.sheet_to_row_object_array(
          workbook.Sheets[sheet],
        )
        return {
          ...rowObject,
        }
      })

      Axios.post('/payments/excel_payment_details_upload', finaldata)
        .then((res) => {
          if (res.data.message == 'success') {
            toast('Success', {
              type: 'success',
              theme: 'dark',
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: false,
            })
            setPaymentFile(false)
          }
        })
        .catch((err) => {
          console.log(err)
        })

      //   setfinalPaymentData(finaldata)
      //   console.log(finaldata, 'final data')
    }
  }

  const columns = [
    { field: 'id', headerName: 'ID Number', width: 120, hide: true },
    { field: 'user_id', headerName: 'Id', width: 120 },
    { field: 'fullname', headerName: 'Name', width: 180 },
    { field: 'email_id', headerName: 'Email id', width: 210 },

    {
      field: 'course_name',
      headerName: 'Course Name',
      width: 200,
    },
    {
      field: 'class_name',
      headerName: 'Class Name',
      width: 200,
    },

    {
      field: 'teacher',
      headerName: 'Teacher name',
      width: 240,
    },
    {
      field: 'slot_time',
      headerName: 'Slot time',
      width: 180,
    },
  ]


  console.log("kkkk", details1)


  return (
    <div>
      <div className="card height-auto">
        <div className="card-body">
          <div className="item-title col-md-4">
            <h4>Updating Student Details</h4>
          </div>
          <div className="heading-layout1 row">
            <div className="item-title col-md-4 add-in">
              <TextField
                id="outlined-basic"
                label="Email Id *"
                variant="outlined"
                onChange={(e) =>
                  setDetails({ ...details, email_id: e.target.value })
                }
              />
              <button
                onClick={() => {
                  onSearch()
                }}
                type="submit"
                className="btn-fill-lg bg-blue-dark btn-hover-yellow add-in-btn"
              >
                Search
              </button>
            </div>

            {rowss.length > 0 && (
              <div className="item-title col-md-3">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Course</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={age}
                    label="Course"
                    onChange={(e) => {
                      onSelectCourse(e.target.value)
                    }}
                  >
                    {courses.map((item) => {
                      return <MenuItem value={item}>{item}</MenuItem>
                    })}
                  </Select>
                </FormControl>
              </div>
            )}
            {rowss.length > 0 && (
              <div className="item-title col-md-3">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Class</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={age}
                    label="Class"
                    onChange={(e) => onSelectClass(e.target.value)}
                  >
                    {classes.map((item) => {
                      return <MenuItem value={item}>{item}</MenuItem>
                    })}
                  </Select>
                </FormControl>
              </div>
            )}
            {rowss.length > 0 && (
              <button
                type="submit"
                className="btn-fill-lg bg-blue-dark btn-hover-yellow add-in-btn all-act-btn"
                onClick={() => onClickUpdate()}
              >
                UPDATE
              </button>
            )}
          </div>

          {/* {rowss ? ( */}
          <div
            className="table-responsive"
            style={{ height: 450, width: '100%' }}
          >
            <DataGrid
              components={{
                Toolbar: GridToolbar,
              }}
              rows={rowss}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10]}
            />
          </div>
          <div className="col-md-2 upl-icn pointer d-flex align-items-center">
            <input
              type="file"
              accept=".xls,.xlsx,.csv"
              name="excell"
              id={'filel'}
              required
              className="donotshow"
              onChange={(e) => {
                // alert()

                paymentExcelFile = e.target.files[0]

                setPaymentFile(true)
              }}
            />{' '}
            {/* {pdfFileError && !item.pdfName && (
                              <div
                                className="text-danger"
                                style={{ margin: "0" }}
                              >
                                {pdfFileError}
                              </div>
                            )} */}
          </div>





          <br></br>
          <br></br>
          <div className="item-title col-md-4" >
            <h4>Update Student Language Preference</h4>
          </div>
          <div className="heading-layout1 row">
            <div className="item-title col-md-4 add-in">
              <TextField
                id="outlined-basic"
                label="Email Id *"
                variant="outlined"
                onChange={(e) =>
                  setDetails({ ...details, email_id: e.target.value })
                }
              />
              <button
                onClick={() => {
                  onSearch1()
                }}
                type="submit"
                className="btn-fill-lg bg-blue-dark btn-hover-yellow add-in-btn"
              >
                Search
              </button>
            </div>

            {userExist &&
              <div className="col-lg-6 col-12 mb-30">
                <div className="item-title col-md-3">
                  <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                    <select
                      style={{ width: "200px", height: "35px", fontSize: "17px", marginRight: "10px" }} // Adjust width and margin as needed
                      className="form-control"
                      name="preference_value"
                      id="preference_value"
                      // value={user?.preference_value}

                      onChange={handleChangePreferenece1}
                      value={user}
                    >
                      <option>Select Language</option>
                      <option value="Telugu" style={{ padding: "10px" }}>Telugu</option>
                      <option value="English" style={{ padding: "10px" }}>English</option>
                      <option value="Sanskrit" style={{ padding: "10px" }}>Sanskrit</option>
                    </select>
                    <button
                      type="submit"
                      className="btn-fill-lg bg-blue-dark btn-hover-yellow add-in-btn all-act-btn"
                      onClick={() => onClickUpdate1()}
                    >
                      UPDATE
                    </button>
                  </div>
                </div>
              </div>


            }



          </div>





          <br></br>
          <br></br>

          <div className="mg-b-40 mg-t-60 upload-section">
            <label className="h-l">Update Payment Details</label>
            {/* <div className="item-title col-md-4 mt-2">
              <h4>Updating Payment Details</h4>
            </div> */}
            <div className="mg-t-20 heading-layout1 row ml-5">
              <div>
                <button
                  type="submit"
                  className="btn-fill-lg bg-blue-dark btn-hover-yellow add-in-btn all-act-btn "
                  onClick={() => {
                    document.getElementById('filel').click()
                    setPaymentFile(false)
                  }}
                >
                  Upload
                </button>
              </div>
              {console.log(paymentFile, 'ppppppppppppp')}
              {paymentFile && <h4>{paymentExcelFile.name}</h4>}
              {paymentFile && (
                <div>
                  <button
                    type="submit"
                    className="btn-fill-lg bg-blue-dark btn-hover-yellow add-in-btn all-act-btn mr-5"
                    onClick={() => {
                      handleSubmitFile()
                    }}
                  >
                    Submit
                  </button>
                </div>
              )}
            </div>
          </div>

          {/* ) : (
            <div>No Data</div>
          )} */}
        </div>
      </div>
    </div >
  )
}

export default Updating
