import React, { useEffect, useState } from "react";
import CourseDetails from "./CourseDetails";
import Glimpse from "./Glimpse";
import Scheddule from "./Schedule";
import Prices from "./Prices.js";
import { useParams } from "react-router";
import Axios from "../../utilities/axios";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { uploadFile } from "react-s3";
import RichTextEditor from "react-rte";

const config = {
  bucketName: process.env.REACT_APP_S3_BUCKET,
  region: process.env.REACT_APP_REGION,
  dirName: "folder",
  accessKeyId: process.env.REACT_APP_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
};

const ManageCourses = () => {
  let { id } = useParams();
  let history = useHistory();
  const [typess, setTypess] = useState([]);
  const [selectedType, setSelectedType] = React.useState({
    id: "",
    type: "",
  });
  const [selectedtypeid, setSelectedtypeid] = React.useState(0);
  const [courseDetails, setCourseDetails] = useState({
    course_description: "",
    course_duration: "",
    course_id: 0,
    course_image: null,
    course_name: "",
    courseadmin: "",
    courseadmin_id: 0,
    price: 0,
    start_date: null,
    what_is: "",
    flag: "",
    note: "",
    what_we_learn: "",
    types: [],
    email_id: "",
  });
  const [whatWeLearn, setWhatWeLearn] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [note, setNote] = React.useState("");
  const [glimpsArray, setGlimpsArray] = useState([]);
  const [prices, setPrices] = useState([]);
  const [deletedprices, setDeletedprices] = useState([]);
  const [months, setMonths] = useState([]);
  const [allweaks, setAllweaks] = useState([]);
  const [dummy, setdummy] = useState(true);
  const [s, setS] = useState(null);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [deletedarray, setDeletedarray] = useState([]);
  const [filess, setFiless] = React.useState([]);
  const [newGlimpseArray, setNewGlimpseArray] = useState([]);
  const [pdfFileError, setPdfFileError] = React.useState("");
  const [seleted, setSeleted] = React.useState([]);
  const [imageChanged, setImageChanged] = React.useState(false);
  // const [selected_course, setSelected_course] = React.useState(null);
  const [status, setStatus] = React.useState(false);
  const [yearMonth, setYearMonth] = React.useState([]);
  React.useEffect(() => {
    getData();
    //eslint-disable-next-linesetSelectedType
  }, []);
  const handleCourseDetailsChange = (e) => {
    if (e?.target?.value?.includes("fakepath")) {
      setImageChanged(true);
      setdummy(false);
      setSelectedFile(e?.target?.files[0]);
      setdummy(false);
      callAfun(e);
    } else if (e?.target?.name) {
      // console.log(e.target.name, "resultsss on change", courseDetails);
      setCourseDetails((ev) => ({
        ...ev,
        [e.target.name]: e.target.value,
      }));
    } else {
      setCourseDetails((ev) => ({
        ...ev,
        start_date: e,
      }));
    }
  };
  const callAfun = async (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setCourseDetails((ev) => ({
          ...ev,
          course_image: reader.result,
        }));
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };
  let duration = {
    semester: 6,
    trimister: 3,
    quarterly: 3,
    yearly: 12,
  };
  useEffect(() => {
    let weaks = toReturnMoths(
      courseDetails?.start_date,
      duration[courseDetails.course_duration]
    );
    let month = [
      ...new Set(weaks.map((val) => moment(val, "DD-MM-YYYY").format("MMMM"))),
    ];
    let yearMonth = [
      ...new Set(
        weaks.map((val) => moment(val, "DD-MM-YYYY").format("MMMM yyyy"))
      ),
    ];
    setYearMonth(yearMonth);
    setMonths(month);
    let startWeak = moment(courseDetails.start_date).format("DD-MM-YYYY");
    let finalWeaks = [];
    weaks.map((val, i) => {
      finalWeaks.push({
        week: `${startWeak}/${val}`,
        topics: [
          {
            weekCount: `week-${i + 1} (${startWeak?.split("-")[0]}-${
              val.split("-")?.[0]
            })`,
            week: `${startWeak}/${val}`,
            topicName: "",
            video: "",
            homework: false,
            pdf: "",
            pdfName: "",
          },
        ],
      });
      startWeak = val;
    });
    setAllweaks(finalWeaks);
    //eslint-disable-next-line
  }, [courseDetails.course_duration, courseDetails.start_date]);

  const toReturnMoths = (fieldValue, duration) => {
    if (!fieldValue && !duration) return [];
    let startDate = fieldValue;
    let endDate = moment(fieldValue).add(duration, "months");
    let numberOfWeaks = weeksBetweenDates(startDate, endDate);
    let a = [];
    let start = moment(startDate).format("DD-MM-YYYY");
    for (let index = 0; index < numberOfWeaks; index++) {
      let newDate = moment(start, "DD-MM-YYYY").add(6, "days");
      a.push(moment(newDate).format("DD-MM-YYYY"));
      start = newDate;
    }
    return a;
  };
  const weeksBetweenDates = (d0, d1, startDay) => {
    // Default start day to 1 (Monday)
    if (typeof startDay != "number") startDay = 1;
    // Copy dates so don't affect originals
    d0 = new Date(d0);
    d1 = new Date(d1);
    // Set dates to the start of the week based on startDay
    [d0, d1].forEach((d) =>
      d.setDate(d.getDate() + ((startDay - d.getDay() - 7) % 7))
    );
    // If d1 is before d0, swap them
    if (d1 < d0) {
      var t = d1;
      d1 = d0;
      d0 = t;
    }
    return Math.round((d1 - d0) / 6.048e8) + 1;
  };
  const handleTopicPdfChange = (e, weekInx, topicInx) => {
    setAllweaks((prevState) => {
      let newState = [...prevState];
      newState[weekInx].topics[topicInx].pdf = "";
      newState[weekInx].topics[topicInx].pdfName = "";
      newState[weekInx].topics[topicInx].state = "EDIT";
      return newState;
    });
    setdummy(false);
  };

  const handleTopicChange = async (e, weekInx, topicInx) => {
    const fileType = ["application/pdf"];
    if (e.target.name === "pdf") {
      let selectedFile = e.target.files[0];
      if (selectedFile) {
        if (selectedFile && fileType.includes(selectedFile.type)) {
          setdummy(false);
          const handleUpload = async (selectedFile) => {
            await uploadFile(selectedFile, config)
              .then((data) => {
                if (data?.result?.ok) {
                  setAllweaks((prevState) => {
                    let newState = [...prevState];
                    newState[weekInx].topics[topicInx].pdfName =
                      e?.target?.files[0]?.name;
                    newState[weekInx].topics[topicInx].pdf = data?.location;
                    newState[weekInx].topics[topicInx].state = "ADD";
                    if (newState[weekInx].topics[topicInx]?.topic_id) {
                      newState[weekInx].topics[topicInx].state = "EDIT";
                    }
                    return newState;
                  });
                } else {
                  alert("error,re-try");
                }
              })
              .catch((err) => {
                console.log(err);
              });
          };
          handleUpload(selectedFile);
          setPdfFileError("");
        } else {
          setPdfFileError("Please select only pdf file");
        }
      }
    } else if (e.target.name === "homework") {
      setAllweaks((prev) => {
        let newArray = [...prev];
        newArray[weekInx].topics[topicInx].homework =
          e.target.checked.toString();
        return newArray;
      });
      setdummy(false);
    } else {
      let data = allweaks;
      data[weekInx].topics[topicInx][e.target.name] = e.target.value;
      let d = data[weekInx].topics[topicInx];
      d.state = "ADD";
      if (d.topic_id) {
        d.state = "EDIT";
      }
      setAllweaks(data);
      // console.log("hello", data);
    }

    setdummy(!dummy);
  };
  const handleTopicDelete = (weekInx, topicInx) => {
    let data = [...allweaks];
    let de = data[weekInx];
    if (de?.topics[topicInx]?.topic_id) {
      de.topics[topicInx]["state"] = "REMOVE";
    } else {
      data[weekInx].topics.splice(topicInx, 1);
    }
    setAllweaks(data);
    setdummy(!dummy);
  };
  const handleTopicReset = (weekInx, topicInx) => {
    let data = allweaks;
    data[weekInx].topics.splice(topicInx, 1, {
      // month: allweaks[weekInx].week,
      // weekCount: `week-${i + 1} (${startWeak?.split("-")[0]}-${
      //   val.split("-")?.[0]
      // })`,
      // week: allweaks[weekInx].week,
      topicName: "",
      video: "",
      homework: false,
      pdf: "",
      pdfName: "",
    });
    setAllweaks(data);
    setdummy(!dummy);
  };
  const addWeekTopics = (indexxx, weekInx) => {
    let data = [...allweaks];
    data[weekInx + indexxx].topics.push({
      week: data[weekInx + indexxx].week,
      weekCount: data[weekInx + indexxx].topics[0].weekCount,
      topicName: "",
      video: "",
      homework: false,
      pdf: "",
      pdfName: "",
    });
    setAllweaks(data);
    setdummy(!dummy);
  };

  const getData = () => {
    const data = {
      course_id: id,
      student_id: 0,
    };
    Axios.post(`courses/get_single_course`, data)
      .then((res) => {
        let datacoming = res?.data?.data;
        setTypess(res?.data?.types);
        setSelectedType(res?.data?.type[0]);
        setSelectedtypeid(res?.data?.type?.[0]?.id);
        setGlimpsArray(datacoming[1]);
        setPrices(datacoming[4]);
        setS(datacoming?.[3]);
        datacoming = datacoming?.[0]?.[0];
        setStatus(res?.data?.flag);
        setCourseDetails(datacoming);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onPriceAdd = () => {
    let data = prices;
    data.push({
      country_name: "",
      currency_code: "",
      price_value: "",
      country_code: "",
    });
    setPrices(data);
    setdummy(!dummy);
  };
  const onPriceDelete = (index) => {
    let data = prices;
    if (data.length > 0) {
      let de = prices[index];
      if (de.id) {
        delete de.country_name;
        delete de.currency_code;
        delete de.price_value;
        prices[index].state = "REMOVE";
        let l = [...deletedprices];
        l.push(de);
        setDeletedprices(l);
        setdummy(!dummy);
      }
      data.splice(index, 1);
      setPrices(data);
      setdummy(!dummy);
    }
  };
  const onPriceHandleChange = (e, index, v) => {
    let data = prices;
    data[index][e.target.name] = v;
    let d = prices[index];
    data[index].state = "ADD";
    if (d.id) {
      data[index].state = "EDIT";
    }
    setPrices(data);
    setdummy(!dummy);
  };
  const handleGlimpseUpdate = async (demoo) => {
    let newglimpse = [];
    if (glimpsArray.length === 0) {
      callMainApi(demoo);
      return;
    }
    glimpsArray.length > 0 &&
      glimpsArray.map(async (glimpse, index) => {
        if (index < glimpsArray.length) {
          if (glimpse.glimpse_file) {
            await uploadFile(glimpse?.glimpse_file, config)
              .then((data) => {
                if (data?.result?.ok) {
                  glimpse.glimpse_image = data?.location;
                  newglimpse.push(glimpse);
                  // console.log(newglimpse, "new glimpse 339");
                  setNewGlimpseArray(newglimpse);
                  setdummy(!dummy);
                  setdummy(!dummy);
                } else {
                  alert("error");
                }
              })
              .catch((err) => console.log(err));
          } else {
            if (glimpse.glimpse_image.includes("base64")) {
              // console.log(glimpsArray, "glimpsearray");
              setdummy(!dummy);
              setdummy(!dummy);
            }
          }
        }
        if (index === glimpsArray.length - 1) {
          callMainApi(demoo);
        }
      });
  };
  const callMainApi = (demo) => {
    let data = courseDetails;
    let newarray = [...newGlimpseArray];
    let newarray1 = [...deletedarray];
    let newarray3 = [...glimpsArray];
    let newarray2 = [...newarray, ...newarray3, ...newarray1];
    let D = {
      added_by: `${data.courseadmin_id}`,
      course_name: `${data.course_name}`,
      type: selectedtypeid === "" ? 0 : selectedtypeid,
      course_duration: `${data.course_duration}`,
      start_date: `${moment(data.start_date).format("YYYY-MMM-DD")}`,
      course_image: `${demo}`,
      description: { description: description },
      course_id: `${data.course_id}`,
      what_we_learn: { what: whatWeLearn },
      note: { note: note },
      prices: [...prices, ...deletedprices],
      glimpse: newarray2,
      total_topics: [...allweaks],
      email_id: `${data.email_id}`,
    };
    Axios.put("courses/edit_course_by_courseadmin", D)
      .then((res) => {
        let r = res?.data;
        if (r?.status === 200) {
          console.log(r, "resultsss");
          toast("Course Updated Successfully", {
            type: "success",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
          history.push(`/single course/${id}`);
        } else {
          toast("Something went wrong", {
            type: "error",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleMainUpload = async (file) => {
    if (imageChanged) {
      uploadFile(file, config)
        .then((data) => {
          if (data?.result?.ok) {
            // setSelected_course(data.location);
            setdummy(!dummy);
            handleGlimpseUpdate(data.location);
          } else {
            alert("Something went wrong");
          }
        })
        .catch((err) => handleGlimpseUpdate());
    } else {
      handleGlimpseUpdate(courseDetails.course_image);
    }
  };
  return (
    <div className="card height-auto">
      {allweaks.length !== 0 ? (
        <div className="card-body">
          <CourseDetails
            selectedType={selectedType}
            setSelectedType={setSelectedType}
            setSelectedtypeid={setSelectedtypeid}
            selectedtypeid={selectedtypeid}
            typess={typess}
            setTypess={setTypess}
            status={status}
            setCourseDetails={setCourseDetails}
            whatWeLearn={whatWeLearn}
            description={description}
            note={note}
            setDescription={setDescription}
            setWhatWeLearn={setWhatWeLearn}
            setNote={setNote}
            setSelectedFile={setSelectedFile}
            courseDetails={courseDetails}
            onChange={handleCourseDetailsChange}
          />
          <Glimpse
            deletedarray={deletedarray}
            setDeletedarray={setDeletedarray}
            glimpsArray={glimpsArray}
            setGlimpsArray={setGlimpsArray}
          />
          <Prices
            prices={prices}
            seleted={seleted}
            setSeleted={setSeleted}
            setPrices={setPrices}
            onPriceAdd={onPriceAdd}
            onPriceDelete={onPriceDelete}
            onPriceHandleChange={onPriceHandleChange}
          />
          <Scheddule
            s={s}
            yearMonth={yearMonth}
            filess={filess}
            setFiless={setFiless}
            pdfFileError={pdfFileError}
            months={months}
            allweaks={allweaks && allweaks}
            setAllweaks={setAllweaks}
            addWeekTopics={addWeekTopics}
            handleTopicDelete={handleTopicDelete}
            handleTopicReset={handleTopicReset}
            handleTopicPdfChange={handleTopicPdfChange}
            handleTopicChange={handleTopicChange}
          />
          <div className="row">
            <div className="col-12 form-group mg-t-8">
              <button
                type="button"
                className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
                onClick={() => {
                  handleMainUpload(selectedFile);
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div id="preloader" />
      )}
    </div>
  );
};

export default ManageCourses;
