import React from "react";
import logo from "../assests/img/books.JPG";
import "react-datepicker/dist/react-datepicker.css";
import { formatDate } from "../utilities/convert";
import { toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import Axios from "../utilities/axios";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { uploadFile } from "react-s3";
import RichTextEditor from "react-rte";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

const config = {
  bucketName: process.env.REACT_APP_S3_BUCKET,
  region: process.env.REACT_APP_REGION,
  dirName: "folder",
  accessKeyId: process.env.REACT_APP_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
};

const Addcourse = (props) => {
  const { className } = props;
  let { id } = useParams();
  const history = useHistory();
  const [res, setRes] = React.useState(null);
  const [modal, setModal] = React.useState(false);
  const [inviteClicked, setInviteClicked] = React.useState(false);
  const [status, setStatus] = React.useState(false);
  const [clicked, setClicked] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [dummy, setDummy] = React.useState(false);
  const [description, setDescription] = React.useState("");
  const [invite, setInvite] = React.useState({
    email_id: "",
  });

  const options = [
    [
      "bold",
      "italic",
      "underline",
      "strike",
      "paragraphStyle",
      "blockquote",
      "list",
      "align",
      "outdent",
      "indent",
      "subscript",
      "superscript",
      "hiliteColor",
      "fontSize",
      "formatBlock",
      "link",
      "image",
      "video",
      "table",
      "audio",
      "font",
      "horizontalRule",
      "lineHeight",
      "fontColor",
      "undo",
      "redo",
      "fullScreen",
      "removeFormat",
      "print",
    ],
  ];
  const [details, setDetails] = React.useState({
    course_name: "",
    //course_description: { description: description },
    course_image: logo,
    courseadmin_id: "",
    course_duration: "",
    start_date: null,
  });
  const toggle = () => setModal(!modal);
  const courseDurationChange = (event) => {
    setDetails({ ...details, course_duration: event.target.value });
  };
  React.useEffect(() => {
    Axios.get("course_admin/get_course_admin_names")
      .then((res) => {
        setRes(res);
      })
      .catch((err) => {
        console.log(err);
      });
    if (history.location.pathname.includes("/editcourse/")) {
      Axios.post(`courses/get_single_course`, {
        student_id: 0,
        course_id: id,
      })
        .then((res) => {
          let aa = res?.data?.data;
          setStatus(res?.data?.flag);
          aa = aa?.[0]?.[0];
          setDetails(aa);

          setDescription(JSON.parse(aa?.course_description).description);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    //eslint-disable-next-line
  }, []);
  // const handleChange = (e) => {
  //   setValues(e);
  //   setDetails({ ...details, course_description: e.toString("html") });
  //   // setDummy(true);
  //   // setDummy(true);
  //   // setDummy(true);
  // };
  const uploadImage = async (e) => {
    setSelectedFile(e.target.files[0]);
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setDetails({ ...details, course_image: reader.result });
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };
  const handleSubmit = (image) => {
    let det = {
      ...details,
      start_date: formatDate(details.start_date),
      course_description: { description: description },
      what_we_learn: { what: "" },
      note: { note: "" },
    };
    if (det.course_name === "") {
      toast("Course name required", {
        type: "warning",
        position: "top-center",
        theme: "dark",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
      setClicked(false);
    } else if (det.courseadmin_id === "") {
      toast("Assaign someone as course Admin", {
        type: "error",
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
      setClicked(false);
    } else {
      let uid = localStorage.getItem("uid");
      det.user_id = uid;
      det.course_id = `${det?.course_id}`;
      if (history.location.pathname.includes("/editcourse/")) {
        delete det.what_we_learn;
        delete det.note;
        delete det.what_is;
        delete det.price;
        det.courseadmin_id = `${det?.courseadmin_id}`;
        det.course_image = image === "NoImage" ? details?.course_image : image;
        Axios.post("courses/edit_course_by_admin", det)
          .then((res) => {
            toast("Course updated successfully", {
              type: "success",
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
            history.push("/courses");
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        // if (det.course_image.includes("media")) {
        //   det.course_image = "NoImage";
        // }
        det.course_image = image;
        Axios.post("/courses/add_course", det)
          .then((res) => {
            if (res.status === 200) {
              toast("Course Added Successfully", {
                type: "success",
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
              });
              history.push("/courses");
            } else {
              toast("Something went wrong", {
                type: "error",
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
              });
              setClicked(false);
            }
          })
          .catch((err) => {
            setClicked(false);
            console.log(err);
          });
      }
    }
  };
  const handleUpload = async (file) => {
    await uploadFile(file, config)
      .then((data) => {
        if (data?.result?.ok) {
          handleSubmit(data?.location);
        } else {
          alert("error");
        }
      })
      .catch((err) => handleSubmit("NoImage"));
  };
  const handleInvite = () => {
    let data = {
      email_id: invite.email_id,
      role: "courseadmin",
    };
    setInviteClicked(true);
    Axios.post("user_details/invite", data)
      .then((res) => {
        toast("Invite sent successfully", {
          type: "success",
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        setInvite({ email_id: "" });
        setInviteClicked(false);
        toggle();
      })
      .catch((err) => {
        alert("Something went wrong");
        console.log(err);
      });
  };
  React.useEffect(() => {
    // console.log(details, "details");
    if (history.location.pathname.includes("/editcourse/")) {
      // alert("edit");
      // console.log(details, "details");
      if (details) {
        // setDummy(!!dummy);
        //setDescription(JSON.parse(details?.course_description).description);
        setDummy(!!dummy);
      }
    }
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <form className="card new-added-form height-auto">
        <div className="card-body">
          <div className="row">
            <div className="col-md-9">
              <div className="row">
                <div className="col-md-6 col-xl-6 col-lg-6 col-12 form-group">
                  <TextField
                    id="outlined-basic"
                    label="Course Name *"
                    variant="outlined"
                    value={details?.course_name}
                    onChange={(e) =>
                      setDetails({ ...details, course_name: e.target.value })
                    }
                  />
                </div>
                <div className="col-md-6 col-xl-6 col-lg-6 col-12 form-group">
                  <FormControl fullWidth>
                    <InputLabel id="course-duration-select-helper-label">
                      Course Duration
                    </InputLabel>
                    <Select
                      labelId="course-duration-select-helper-label"
                      id="course-duration-select-helper"
                      value={details?.course_duration}
                      label="Course Duration"
                      // disabled={status}
                      onChange={courseDurationChange}
                      disabled={
                        history.location.pathname.includes("/editcourse/")
                          ? status
                          : false
                      }
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={"semester"}>Semester</MenuItem>
                      <MenuItem value={"trimister"}>Trimister</MenuItem>
                      <MenuItem value={"quarterly"}>Quarterly</MenuItem>
                      <MenuItem value={"yearly"}>Yearly</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-xl-6 col-lg-6 col-12 form-group add-in">
                  <FormControl fullWidth>
                    <InputLabel id="course-admin-select-helper-label">
                      Course Admin *
                    </InputLabel>
                    <Select
                      labelId="course-admin-select-helper-label"
                      id="course-admin-select-helper"
                      value={details?.courseadmin_id}
                      label="Course Admin *"
                      onChange={(e) =>
                        setDetails({
                          ...details,
                          courseadmin_id: `${e.target.value}`,
                        })
                      }
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {res &&
                        res.data &&
                        res.data.data &&
                        res.data.data?.map((name) => (
                          <MenuItem value={name?.user_id}>
                            {name?.fullname}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      toggle();
                    }}
                    type="submit"
                    className="btn-fill-lg bg-blue-dark btn-hover-yellow add-in-btn"
                  >
                    + Invite
                  </button>
                </div>
                <div className="col-md-6 col-xl-6 col-lg-6 col-12 form-group">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Start Date of the Course"
                      disabled={
                        history.location.pathname.includes("/editcourse/")
                          ? status
                          : false
                      }
                      value={details?.start_date}
                      // mask="__-__-____"
                      mask="dd-MM-yyyy"
                      inputFormat="dd-MMM-yyyy"
                      disablePast={true}
                      onChange={(newValue) => {
                        setDetails({
                          ...details,
                          start_date: newValue,
                        });
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-xl-12 col-lg-12 col-12">
                  <div className="form-floating">
                    {/* <RichTextEditor
                      placeholder="Write Something About Yourself"
                      value={value}
                      onChange={(e) => handleChange(e)}
                    /> */}
                    <SunEditor
                      onChange={(e) => {
                        setDescription(e);
                        console.log(e, "hellllllllll", description, "deeed");
                      }}
                      setContents={description}
                      placeholder="Write Something About Yourself"
                      setOptions={{
                        buttonList: options,
                        videoWidth: "530px",
                        videoHeight: "300px",
                        videoResizing: false,
                        videoAlignShow: false,
                        imageWidth: "auto",
                        imageHeight: "auto",
                        imageResizing: false,
                        imageAlignShow: false,
                      }}
                    />

                    {/* <textarea
                      className="form-control"
                      placeholder="Enter Course Description(optional)"
                      id="floatingTextarea2"
                      rows="5"
                      value={details?.course_description}
                      onChange={(e) =>
                        setDetails({
                          ...details,
                          course_description: e.target.value,
                        })
                      }
                    /> */}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 form-group mg-t-8">
                  {history.location.pathname.includes("/editcourse/") ? (
                    <>
                      <button
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          handleUpload(selectedFile);
                        }}
                        className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
                      >
                        Update Course
                      </button>
                      <button
                        type="reset"
                        className="btn-fill-lg bg-blue-dark btn-hover-yellow"
                      >
                        Cancel
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        type="button"
                        onClick={() => {
                          setClicked(true);
                          handleUpload(selectedFile);
                        }}
                        className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
                      >
                        {!clicked ? "Add Course" : "Please wait.."}
                      </button>
                      <button
                        type="reset"
                        onClick={() => {
                          setDetails({
                            course_name: "",
                            course_description: "",
                            course_image: logo,
                            course_duration: "",
                            start_date: null,
                            courseadmin_id: "",
                          });
                        }}
                        className="btn-fill-lg bg-blue-dark btn-hover-yellow"
                      >
                        Reset
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="up-img-sec">
                <img
                  src={details?.course_image}
                  alt="mage"
                  className="image-fluid pointer"
                  onClick={() => document.getElementById("upload").click()}
                />
                <i
                  className="fas fa-cloud-upload-alt pointer"
                  onClick={() => document.getElementById("upload").click()}
                ></i>
                <center>
                  <input
                    id="upload"
                    type="file"
                    accept="image/*"
                    onChange={(e) => uploadImage(e)}
                    className="donotshow"
                  />
                </center>
              </div>
            </div>
          </div>
        </div>
      </form>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>Invite New Course Admin</ModalHeader>
        <ModalBody>
          <form className="new-added-form">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-12 form-group">
                <TextField
                  id="outlined-basic"
                  label="Enter Email of the Course Admin"
                  variant="outlined"
                  type="email"
                  value={invite.email_id}
                  onChange={(e) => {
                    setInvite({
                      email_id: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => handleInvite()}
            className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
          >
            {inviteClicked ? "Please Wait..." : "Invite"}
          </Button>{" "}
          <Button
            color="secondary"
            onClick={toggle}
            className="btn-fill-lg bg-blue-dark btn-hover-yellow"
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Addcourse;
