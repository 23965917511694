import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import Axios from "../../utilities/axios";
import React from 'react'
import AddEventModel from './AddEventModel'
import StudentEventRegisterList from './StudentEventRegisterList'
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { toast } from 'react-toastify';
import swal from "sweetalert";
import moment from 'moment';
import Tooltip from "@mui/material/Tooltip";
const Events = () => {
    const [rows, setRows] = React.useState([])
    const [modelOpen, setModelOpen] = React.useState(false)
    const [studentsDetails, setStudentsDetails] =React.useState([])



    const columns = [
        { field: 'id', headerName: 'ID Number', width: 120, hide: true },
        { field: 'event_name', headerName: 'Event Name', width: 180 },
        { field: 'course_name', headerName: 'Course', width: 150 },
        {
            field: 'event_date', headerName: 'Date', width: 180,
            renderCell: (params) => (moment(params.row.start_time)
                .tz(moment.tz.guess())
                .format("DD-MMM-YYYY"))
        },
        {
            field: 'start_time', headerName: 'Start Time', width: 180,
            renderCell: (params) => (moment(params.row.start_time)
                .tz(moment.tz.guess())
                .format("hh:mm A"))
        },
        {
            field: 'end_time', headerName: 'End Time', width: 180, renderCell: (params) => (moment(params.row.end_time)
                .tz(moment.tz.guess())
                .format("hh:mm A"))
        },
        { field: 'meeting_link', headerName: 'Event Link', width: 180 },
        { field: 'live_status', headerName: 'Status', width: 150 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 500,
            renderCell: (params) => (
                RenderDetailsButton(params, setRows,setStudentsDetails)
            ),
            disableClickEventBubbling: true,
            filterable: false, 
        }

    ]


    React.useEffect(() => {
        callApi();
    }, [])
   

    const callApi = () => {
        Axios.get(`/events/get_events`)
            .then((res) => {

                setRows(res.data.data)
            })
            .catch((err) => {
                toast("Something went Wrong", {
                    type: "error",
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            });
    }
    console.log(rows,studentsDetails,"event page")
    return (
        <div>
            <div className="card height-auto">
                <div className="card-body">
                    <div className="item-title col-md-12 new-btn-sty">
                        <h4>Events List</h4>
                        <button
                            onClick={() => {
                                setModelOpen(true)
                            }}
                            type="submit"
                            className="btn-fill-lg bg-blue-dark btn-hover-yellow add-in-btn"
                        >
                            Add Event
                        </button>
                    </div>
                    <div
                        className="table-responsive"
                        style={{ height: 450, width: '100%' }}
                    >
                        <DataGrid
                            components={{
                                Toolbar: GridToolbar,
                            }}
                            rows={rows}
                            columns={columns}
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                        />
                    </div>
                    <StudentEventRegisterList event_rows={rows} studentsDetails={studentsDetails} />

                </div>
            </div>
            <AddEventModel modelOpen={modelOpen} setModelOpen={setModelOpen} setRows={setRows} />
        </div>

    )
}

export default Events

const RenderDetailsButton = (params, setRows,setStudentsDetails) => {
    
    
    const [type, setType] = React.useState('');
    const [singleEventData, setSingleEventData] = React.useState([])
    const [modelOpen, setModelOpen] = React.useState(false)
    const [emailStatus, setEmailStatus] = React.useState(false)

    const viewFun = (param) => {
        // console.log(param,"paramssss")
        Axios.get(`/events/get_single_events/${param.row.event_id}`)
            .then((res) => {
                if (res.data.status == 200) {
                    setSingleEventData(res.data.data);
                    setModelOpen(true);


                }
                else {
                    toast("Something went Wrong", {
                        type: "error",
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                }


            })
            .catch((err) => {
                console.log(err);
            });

    }
    const onDeleteEvent = (param) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, Event will deleted permanentley",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                Axios.get(`/events/delete_event/${param.row.event_id}`)
                    .then((res) => {
                        if (res.data.message == 'success') {
                            setRows(res?.data?.result)
                            toast('Successfully Deleted the event', {
                                type: "success",
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                        }
                        else {
                            toast(res.data.message, {
                                type: "error",
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                        }


                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        })
    }

    const onPublishEvent = (param) => {

        swal({
            title: "Are you sure?",
            text: "Once Published, Event will Published to rushividyabhyasam ",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                const data = {
                    event_id: param.row.event_id,
                    flag: emailStatus ? 1 : 0,
                    course_name: param.row.course_name,
                    event_name: param.row.event_name
                }
                console.log("Are you sure", data)
                Axios.post(`/events/publish_event`, data)
                    .then((res) => {
                        if (res.data.status == 200) {

                            setRows(res?.data?.result)
                            toast(res?.data?.message, {
                                type: "success",
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                            setEmailStatus(false)

                        }
                        else {
                            toast("Something went Wrong", {
                                type: "error",
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                        }


                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        })
    }

    const event_registrations = (param) => {
        swal({
            title: "Are you sure?",
            text: `Once you click,  ${param.row.registration_status === 'active'?'Registrations will be closed':"Registrations will be opened"}`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                Axios.get(`/events/event_registrations/${param.row.event_id}`)
                    .then((res) => {
                        if (res.data.status == 200) {

                            setRows(res?.data?.result)
                            toast(res?.data?.message, {
                                type: "success",
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });

                        }
                        else {
                            toast("Something went Wrong", {
                                type: "error",
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                        }


                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        })
    }

    const cancel_event =(param)=>{
        swal({
            title: "Are you sure?",
            text: "Once You click ok, The event will be cancelled ",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                
                const data = {
                    event_id: param.row.event_id,
                   
                    course_name: param.row.course_name,
                    event_name: param.row.event_name
                }
                Axios.post(`/events/cancel_event`,data)
                    .then(async(res) => {
                        if (res.data.status == 200) {

                            
                            toast(res?.data?.message, {
                                type: "success",
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                            const rows= await res?.data?.students
                            
                            setRows(res?.data?.result)
                            setStudentsDetails(rows.length>0?rows:[])
                            
                        }
                        else {
                            toast("Something went Wrong", {
                                type: "error",
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                        }


                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        })
    }

    const email_Status = () => {
        setEmailStatus(!emailStatus)
    }
    return (
        <strong>
            <button
                variant="contained"
                color="primary"
                size="small"
                className="btn btn-primary"
                onClick={() => {
                    setType('view')
                    //   setModelOpen(true)
                    viewFun(params)
                }}
            >
                View
            </button>{' '}
            <button
                variant="contained"
                color="standard"
                size="small"
                className="btn btn-primary"
                onClick={() => {
                    setType('edit')
                    //   setModelOpen(true)
                    viewFun(params)
                }}
            >
                Edit
            </button>{' '}
            <button
                className='btn btn-danger'
                disabled={params?.row?.live_status==='pending'?true:false}
                onClick={() => {
                    cancel_event(params)
                }}
            >
                cancel

                {/* {params?.row?.live_status === '' ? 'publish' : params?.row?.live_status === 'published' ? 'published' : 'canceled'} */}

            </button>{' '}
            <button
                className={params?.row?.live_status === 'published'
                    ? 'btn btn-success'
                    : 'btn btn-danger'}
                    disabled={params?.row?.live_status==='published'?true:false}
                onClick={() => {
                    onPublishEvent(params)
                }}
            >
                {params?.row?.live_status === 'pending' ? 'publish' : params?.row?.live_status === 'published' ? 'published' : 'canceled'}

            </button>{' '}
            
            <button
                className={
                    params?.row?.status === 'published'
                        ? 'btn btn-dark'
                        : 'btn btn-danger'
                }
                onClick={() => {
                    onDeleteEvent(params)
                }}
            >
                Delete
            </button>{' '}
            <button
                className={
                    params?.row?.registration_status === 'active'
                        ? 'btn btn-warning'
                        : 'btn btn-info'

                }
                onClick={() => {
                    event_registrations(params)
                }}
            // disabled={params?.row?.status === 'coming_soon'}
            >
                {params?.row?.registration_status === 'active' ? 'close registrations' : 'open registrations'}

            </button>{" "}
            {/* <FormGroup> */}
            <Tooltip title="If you want send mails to students when publish the event,please click check box">
                
            <FormControlLabel control={<Checkbox checked={emailStatus} />} label="Emails Send" onChange={() => email_Status()} />
                </Tooltip>
            {/* </FormGroup> */}
            {modelOpen && <AddEventModel singleEventData={singleEventData}
                type={type}
                modelOpen={modelOpen}
                setModelOpen={setModelOpen}
                setRows={setRows}
            />}
        </strong>




    )
}