import React from "react";
// import { Link } from "react-router-dom";

const Mycard = ({ iClass, name, number, src }) => {
  return (
    <div className="dashboard-summery-one mg-b-20">
      <div className="row align-items-center">
        <div className="col-3">
          <div className="item-icon bg-light-blue">
            {!src ? (
              <i className={iClass} />
            ) : (
              <img
                src={src}
                className="image-fluid"
                width="85"
                height="85"
                alt=""
              />
            )}
          </div>
        </div>
        <div className="col-9">
          <div className="item-content">
            <div className="item-title">{name}</div>
            <div className="item-number">
              <span className="counter" data-num={number}>
                {number}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mycard;
