export const admin = [
  {
    name: "Dashboard",
    icon: "flaticon-dashboard",
    path: "/",
    childs: [
      // {
      //   path: "/",
      //   name: "Account Admin",
      // },
      {
        path: "/Course admins",
        name: "Course Admins",
      },
      {
        path: "/teachers",
        name: "Teachers",
      },
      {
        path: "/students",
        name: "Students",
      },
      {
        path: "/payments",
        name: "Payments",
      },
      {
        path: "/eventtickets",
        name: "Event Tickets",
      },
      {
        path: "/queryEmails",
        name: "Query Emails",
      },
    ],
  },
  {
    name: "Courses",
    icon: "flaticon-books",
    path: "",
    childs: [
      {
        path: "/addcourse",
        name: "Add course",
      },
      {
        path: "/courses",
        name: "View All Courses",
      },
    ],
  },
];

export const teacher = [
  // {
  //   name: "Teacher Dashboard",
  //   icon: "flaticon-dashboard",
  //   path: "/Teacher Dashboard",
  //   childs: [],
  // },
  {
    name: "Courses",
    icon: "flaticon-open-book",
    path: "/All Courses",
    childs: [],
  },
  {
    name: "Students",
    icon: "flaticon-classmates",
    path: "/Student List",
    childs: [],
  },
  {
    name: "Schedule",
    icon: "flaticon-calendar",
    path: "/schedule",
    childs: [],
  },
  {
    name: "Attendance",
    icon: "flaticon-checklist",
    path: "/attendance",
    childs: [],
  },
  {
    name: "Homeworks",
    icon: "flaticon-shopping-list",
    path: "/homeworks",
    childs: [],
  },
  {
    name: "Attendance New",
    icon: "flaticon-attendance",
    path: "/",
    childs: [
      {
        path: "/mark-attendance",
        name: "Mark Attendance",
      },
      {
        path: "/attendance-report",
        name: "Attendance Report",
      },
    ],
  },
];

export const courseadmin = [
  {
    name: "Courses",
    icon: "flaticon-books",
    path: "/my courses",
    childs: [],
  },
  {
    name: "Course Association",
    icon: "flaticon-maths-class-materials-cross-of-a-pencil-and-a-ruler",
    path: "/Course Association",
    childs: [],
  },
  {
    name: "Teachers",
    icon: "flaticon-multiple-users-silhouette",
    path: "/Teachers List",
    childs: [],
  },
  {
    name: "Students",
    icon: "flaticon-classmates",
    path: "/All Students",
    childs: [],
  },
  {
    name: "Payments",
    icon: "flaticon-technological",
    path: "/payment",
    childs: [],
  },
  {
    path: "/updating",
    name: "Updating",
    icon: "flaticon-dashboard",
    childs: [],
  },
  {
    path: "/events",
    name: "Events",
    icon: "flaticon-dashboard",
    childs: [],
  },
  {
    name: "Attendance",
    icon: "flaticon-attendance",
    path: "/",
    childs: [
      {
        path: "/mark-attendance",
        name: "Mark Attendance",
      },
      {
        path: "/attendance-report",
        name: "Attendance Report",
      },
    ],
  },
];
