import React from 'react'
import Axios from '../utilities/axios'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { useHistory } from 'react-router'

const Viewallcourses = () => {
  let history = useHistory()
  const [rows, setRows] = React.useState(null)
  React.useEffect(() => {
    Axios.get('/courses/get_all_courses')
      .then((res) => {
        let r = res?.data?.data[0]

        setRows(r)
      })
      .catch((err) => {
        console.log(err)
      })
    //eslint-disable-next-line
  }, [])
  const tableRowClicked = (e) => {
    history.push(`/course/${e[0]}`)
  }

  const columns = [
    { field: 'id', headerName: 'Course ID', width: 160 },
    // { field: "id", headerName: "Course ID", width: 160 },
    {
      field: 'course_name', headerName: 'Course Name',
      width: 300,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 180,
      type: 'date',
    },
    {
      field: 'course_publish_unpublish_date',
      headerName:'Publish Date',
      width: 180,
      type: 'date',
    },
    {
      field: 'created_at',
      headerName:'Created Date',
      width: 180,
      type: 'date',
    },
    {
      field: 'course_duration',
      headerName: 'Duration',
      width: 200,
    },
    {
      field: 'course_admin',
      headerName: 'Course Admin',
      width: 200,
      type: 'string',
    },
    {
      field: 'start_date',
      headerName: 'Start Date',
      width: 180,
      type: 'date',
    },
  
  
  ]

  return (
    <>
      {rows ? (
        <div className="card height-auto">
          <div className="card-body">
            <div
              className="table-responsive"
              style={{ height: 550, width: '100%' }}
            >
              <DataGrid
                components={{
                  Toolbar: GridToolbar,
                }}
                rows={rows}
                columns={columns}
                pageSize={8}
                rowsPerPageOptions={[5]}
                onSelectionModelChange={(newSelection) =>
                  tableRowClicked(newSelection)
                }
              />
            </div>
          </div>
        </div>
      ) : (
        <div id="preloader" />
      )}
    </>
  )
}

export default Viewallcourses
