import React from "react";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
// import img from "../../assests/img/figure/user4.jpg";
// import RichTextEditor from "react-rte";
import Axios from "../../utilities/axios";
import { toast } from "react-toastify";
// import NewTextEditor from "../NewTextEditor";
// import { EditorState, convertToRaw } from "draft-js";
// import draftToHtml from "draftjs-to-html";
// import SunTextEditor from "../SunTextEditor";
// import SunTextEditor1 from "../SunTextEditor1";
// import CustomTextEditor from "../CustomTextEditor";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

const CourseDetails = ({
  courseDetails,
  setCourseDetails,
  onChange,
  status,
  typess,
  setTypess,
  selectedType,
  setSelectedType,
  setSelectedtypeid,
  selectedtypeid,
  whatWeLearn,
  description,
  note,
  setDescription,
  setWhatWeLearn,
  setNote,
}) => {
  const options = [
    [
      "bold",
      "italic",
      "underline",
      "strike",
      "paragraphStyle",
      "blockquote",
      "list",
      "align",
      "outdent",
      "indent",
      "subscript",
      "superscript",
      "hiliteColor",
      "fontSize",
      "formatBlock",
      "link",
      "image",
      "video",
      "table",
      "audio",
      "font",
      "horizontalRule",
      "lineHeight",
      "fontColor",
      "undo",
      "redo",
      "fullScreen",
      "removeFormat",
      "print",
    ],
  ];

  const [EditDelete, setEditDelete] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  // const [values, setvaluess] = React.useState("");
  // const handleDescription = (e) => {
  //   setvalues(e);
  //   console.log(e, "++++++++++++++++++++++++++");
  //   setCourseDetails({
  //     ...courseDetails,
  //     course_description: e,
  //   });
  // };
  // const handlewhatwelearn = (e) => {
  //   const newval1 = e;
  //   const v = draftToHtml(convertToRaw(newval1?.getCurrentContent()));
  //     setvaluess(v);
  //     setCourseDetails({
  //       ...courseDetails,
  //       what_we_learn: v,
  //     });
  // };
  // const handlewhatwelearn = (e) => {
  //   setvaluess(e);
  //   setCourseDetails({ ...courseDetails, what_we_learn: e });
  //
  // };
  //console.log(courseDetails, "################################");
  // const handlenote = (e) => {
  //   const newval2 = e;
  //   const n = draftToHtml(convertToRaw(newval2?.getCurrentContent()));
  //    setNote(n);
  //     setCourseDetails({
  //       ...courseDetails,
  //       note: n,
  //     });
  //   }
  // };
  // const handlenote = (e) => {
  //
  //   setNote(e);
  //   console.log(courseDetails.note,"courseDetails")
  //   setCourseDetails({
  //     ...courseDetails,
  //     note: e,
  //   });
  //
  // };

  React.useEffect(() => {
    setDescription(JSON.parse(courseDetails?.course_description).description);
    // console.log(courseDetails.course_description, "DDDD");
    setWhatWeLearn(JSON.parse(courseDetails?.what_we_learn).what);
    //console.log(courseDetails.what_we_learn, "WWWWWWWWW");
    setNote(JSON.parse(courseDetails?.note).note);
    //  console.log(courseDetails.note, "NNNNNNNNNNNNNNN");
    //eslint-disable-next-line
  }, []);
  const handleClick = () => {
    setEditDelete(false);
    setSelectedType({
      type: "",
      id: "",
    });
    toggle();
  };
  const onChanged = (e) => {
    setSelectedtypeid(e.target.value);
    setSelectedType({
      ...selectedType,
      id: e.target.value,
    });
  };
  const toggle = () => setModal(!modal);
  const handleSubmit = () => {
    // let Data = {
    //   type: "bhagavath geetha",
    // };
    // console.log(newType, "newType");
    Axios.post("courses/add_course_type", selectedType)
      .then((res) => {
        console.log(res.data, "ressssssssssssss");
        if (res.data.status === 200) {
          toast(res?.data?.message, {
            type: "success",
            theme: "dark",
          });
          setTypess(res?.data?.data);
          setSelectedType({
            type: "",
            id: "",
          });
          toggle();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // console.log(selectedType, "selectedType");
  const handleEdit = () => {
    if (selectedType.id == "") {
      toast("Please select type", {
        type: "error",
        theme: "dark",
      });
      return;
    }
    setEditDelete(true);
    toggle();
    let a = [];
    for (let i = 0; i < typess.length; i++) {
      if (typess[i].id == selectedType.id) {
        a.push(typess[i]);
      }
    }
    // console.log(a, "a");
    let b = {
      type: a[0].type,
      id: a[0].id,
    };
    setSelectedType(b);
  };
  const handleEditSubmit = () => {
    if (selectedType.type == "") {
      toast("Please select type", {
        type: "error",
        theme: "dark",
      });
      return;
    }
    let data = {};
    data.state = "EDIT";
    data.id = selectedType.id;
    data.type = selectedType.type;
    Axios.post("courses/edit_course_type", data)
      .then((res) => {
        if (res?.data?.status === 200) {
          toast(res?.data?.message, {
            type: "success",
            theme: "dark",
          });
          setTypess(res?.data?.data);
          setSelectedType(selectedType);
          toggle();
          setEditDelete(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleDelete = () => {
    if (window.confirm("Are you sure you want to delete this type?")) {
      let data = {
        type: selectedType.type,
        id: selectedType.id,
        state: "DELETE",
      };
      Axios.post("courses/edit_course_type", data)
        .then((res) => {
          console.log(res.data, "ressssssssssssss");
          if (res.data.status === 200) {
            toast(res?.data?.message, {
              type: "success",
              theme: "dark",
            });
            setTypess(res?.data?.data);
            setSelectedType({
              type: "",
              id: "",
            });
            toggle();
            setEditDelete(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  //console.log(value,"courseDetails123",note,"=====",description)
  return (
    <>
      <section className="mg-b-20">
        <div className="row">
          <div className="col-md-4 col-xl-4 col-lg-4 col-12 form-group">
            <TextField
              id="outlined-basic"
              label="Course Name"
              name="course_name"
              value={courseDetails?.course_name}
              onChange={(e) => onChange(e)}
              // disabled={status}
              disabled={status}
            />
          </div>
          <div className="col-md-4 col-xl-4 col-lg-4 col-12 form-group">
            <FormControl fullWidth>
              <InputLabel id="course-duration-select-helper-label">
                Course Duration
              </InputLabel>
              <Select
                labelId="course-duration-select-helper-label"
                id="course-duration-select-helper"
                label="Course Duration"
                name="course_duration"
                value={courseDetails?.course_duration}
                onChange={(e) => onChange(e)}
                disabled={status}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={"semester"}>Semester</MenuItem>
                <MenuItem value={"trimister"}>Trimister</MenuItem>
                <MenuItem value={"quarterly"}>Quarterly</MenuItem>
                <MenuItem value={"yearly"}>Yearly</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="col-md-4 col-xl-4 col-lg-4 col-12 form-group flx">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Start Date"
                name="start_date"
                mask="dd-MM-yyyy"
                inputFormat="dd-MMM-yyyy"
                value={courseDetails?.start_date}
                disablePast={true}
                onChange={(e) => onChange(e)}
                disabled={status}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <div
              className="upl"
              onClick={(e) => {
                document.getElementById("iffff").click();
              }}
            >
              <img
                src={courseDetails?.course_image}
                alt="No pic"
                style={{
                  objectFit: "contain",
                }}
                className="image-fluid"
              />
              <i className="fas fa-cloud-upload-alt pointer"></i>
              <input
                type="file"
                accept="image/*"
                id="iffff"
                name="course_image"
                onChange={(e) => onChange(e)}
                className="d-none"
              />
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-4">
            <FormControl fullWidth>
              <InputLabel id="course-select-helper-label">
                Course Type
              </InputLabel>
              <Select
                labelId="course-select-helper-label"
                id="course-select-helper"
                label="Course Type"
                name="course_type"
                value={`${selectedType?.id}`}
                onChange={(e) => onChanged(e)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {typess &&
                  typess?.map((item, index) => {
                    return (
                      <MenuItem key={index} value={`${item.id}`}>
                        {item.type}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </div>
          <div className="col-md-4 col-xl-4 col-lg-4 col-12 form-group">
            <TextField
              id="outlined-basic"
              label="Email"
              name="email_id"
              value={courseDetails?.email_id}
              onChange={(e) => onChange(e)}
              // disabled={status}
              // disabled={status}
            />
          </div>
          <div className="col-md-4.5">
            <button
              onClick={() => handleClick()}
              className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark radius-30 mg-t-10"
            >
              Add New Type
            </button>
            <button
              onClick={() => handleEdit()}
              className="btn-fill-lg bg-blue-dark btn-hover-yellow add-in-btn radius-30"
            >
              Edit Type
            </button>
          </div>
        </div>
        <div className="row mg-b-20">
          <div className="col-md-12 col-xl-12 col-lg-12 col-12 ">
            {/* <RichTextEditor
              placeholder="Course Description"
              value={value}
              style={{ minHeight: 410 }}
              onChange={(e) => handleChange(e)}
            /> */}
            <SunEditor
              onChange={(e) => {
                setDescription(e);
              }}
              setContents={description}
              placeholder="Course Description"
              setOptions={{
                buttonList: options,
                videoWidth: "530px",
                videoHeight: "300px",
                videoResizing: false,
                videoAlignShow: false,
                imageWidth: "auto",
                imageHeight: "auto",
                imageResizing: false,
                imageAlignShow: false,
              }}
            />
          </div>
        </div>
        <div className="row mg-b-20">
          <div className="col-md-12 col-xl-12 col-lg-12 col-12 h-25">
            {/* <RichTextEditor
              placeholder="What Student(We) Can Learn"
              value={values}
              // readOnly={true}
              onChange={(e) => handleChanges(e)}
            /> */}
            <SunEditor
              onChange={(e) => {
                console.log(e, "123what");
                if (e == "<p><br></p>") {
                  setWhatWeLearn("");
                } else {
                  setWhatWeLearn(e);
                }
              }}
              setContents={whatWeLearn}
              autoFocus={false}
              placeholder="What Student(We) Can Learn"
              setOptions={{
                buttonList: options,
                videoWidth: "530px",
                videoHeight: "300px",
                videoResizing: false,
                videoAlignShow: false,
                imageWidth: "auto",
                imageHeight: "auto",
                imageResizing: false,
                imageAlignShow: false,
              }}
            />
          </div>
        </div>
        <div className="row mg-b-20">
          <div className="col-md-12 col-xl-12 col-lg-12 col-12">
            {/* <RichTextEditor
              placeholder="Additional Information"
              value={note}
              // customStyleMap={{ font: "Roboto" }}
              // readOnly={true}
              onChange={(e) => handleNote(e)}
            /> */}
            {/* <CustomTextEditor
              onchange={handlenote}
              value={note}
              placeholder="Additional Information"
            /> */}
            {/* <SunTextEditor
              onchange={(e) => handlenote(e)}
              value={note}
              placeholder="Additional Information"
            /> */}
            <SunEditor
              onChange={(e) => {
                // setCourseDetails({
                //   ...courseDetails,
                //   note: e,
                // });
                setNote(e);
                console.log(e, "123note");
              }}
              setContents={note}
              autoFocus={false}
              placeholder="Additional Information"
              setOptions={{
                buttonList: options,
                videoWidth: "530px",
                videoHeight: "300px",
                videoResizing: false,
                videoAlignShow: false,
                imageWidth: "auto",
                imageHeight: "auto",
                imageResizing: false,
                imageAlignShow: false,
              }}
            />
          </div>
        </div>
      </section>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          {!EditDelete ? "Add New" : "Edit"} Course Type
        </ModalHeader>
        <ModalBody>
          <form className="new-added-form">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-12 form-group">
                <TextField
                  id="outlined-basic"
                  label="Enter New Course Type"
                  variant="outlined"
                  type="text"
                  value={selectedType?.type}
                  onChange={(e) => {
                    setSelectedType({
                      ...selectedType,
                      type: e?.target?.value,
                    });
                  }}
                />
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          {EditDelete ? (
            <Button
              color="primary"
              onClick={() => handleDelete()}
              className="btn btn-danger"
            >
              <i className="fas fa-trash"></i> Delete
            </Button>
          ) : null}
          <Button
            color="primary"
            onClick={() => {
              if (!EditDelete) {
                handleSubmit();
              } else {
                handleEditSubmit();
              }
            }}
            className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
          >
            {!EditDelete ? "SUBMIT" : "Update"}
          </Button>{" "}
          {/* <Button
            color="secondary"
            onClick={toggle}
            className="btn-fill-lg bg-blue-dark btn-hover-yellow"
          >
            Cancel
          </Button> */}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default CourseDetails;
