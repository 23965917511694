import React, { useState } from "react";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import Axios from "../utilities/axios";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useEffect } from "react";
import { toast } from "react-toastify";

const Lables = () => {
  const [courseType, setCourseType] = useState({ course_type_name: "" });
  const [email, setEmail] = useState({ email_id: "" });
  const [showDelBtn, setShowDelBtn] = useState(false);
  const [emailData, setEmailData] = useState([]);
  const [data, setData] = useState([
    { course_type_id: "", email_id: '' }
  ]);
  const [updatedData, setUpdatedData] = useState([])
  const [queryTypes, setQueryTypes] = useState([])
  const handleChange = (e) => {
    setCourseType({ ...courseType, course_type_name: e.target.value });
  };
 

  const onClickCourseName = async (e) => {
 e.preventDefault();
     if (courseType.course_type_name != "") {
      let response = await Axios.post(
        "course_type_admin/add_admin_course_type",
        courseType
      );
      if (response?.data?.status == 200) {
        getEmailDetails()
        toast("Successfully Added QueryType", {
          type: "success",
          position: "top-center",
          theme: "dark",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        setCourseType({ course_type_name: "" })
}
     
    } else {
      alert("please enter course name and click add");
    }
  }
  
  const onEmailAdd = () => {
    const pushdata = [...data];
    pushdata.push({
      course_type_name: "",
      course_type_id: "",

      email_id: ""
    });
    setData(pushdata);

  };

  const handleQueryChange = (item, index, value) => {
    const pushdata = [...data];
    //console.log(pushdata[index],"ravi",e,index)
    console.log(item.course_type_mapp_id, "ravi")
    pushdata[index].course_type_id = value;
    pushdata[index].flag = !!item.course_type_mapp_id ? 2 : 1


    setData(pushdata);

  }

  const handleEmailChange = (item, index, value) => {
    
    const pushdata = [...data];
    console.log(!!item.course_type_mapp_id, "ravi")
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
      pushdata[index].error = false;
    }
    else {
      pushdata[index].error = true;
    }
    pushdata[index].email_id = value;
    pushdata[index].flag = !!item.course_type_mapp_id ? 2 : 1


    setData(pushdata);

  }
  const handleDeleteChange = (item, index, value) => {
    const pushdata = [...data];
    const pushdata1 = [...updatedData];
     pushdata[index].flag = 3
    
    if (!!pushdata[index].course_type_mapp_id) {

      pushdata1.push(pushdata[index])
    }
    pushdata.splice(index, 1)

    setData(pushdata);
    setUpdatedData(pushdata1)

  }

  const addNewEmail = (value, type) => {
    const pushdata = [...data]
    if (type === 'email') {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
        pushdata.error = false;
      }
      else {
        pushdata.error = true;
      }
      pushdata.email_id = value
      pushdata.flag = 1
    }
    else {
      pushdata.course_type_id = value
      pushdata.flag = 1
    }
    setData(pushdata);
  }



  async function getEmailDetails() {
    let response = await Axios.get("course_type_admin/get_all_admin_course_types_admin")
    let response1 = await Axios.get("course_type_admin/get_all_admin_couses_and_emails");
    let data2 = response?.data?.data;
    let data1 = response1?.data?.data;
    
    setData(data1.length > 0 ? data1 : data);
    setQueryTypes(data2)
  }

  useEffect(() => {
    getEmailDetails()
  }, [])

  const onSubmit = async () => {
    const editedObjectes = data.filter(item => item.hasOwnProperty("flag"));
    let changedData = [...updatedData, ...editedObjectes]

    console.log(changedData, "changedData[0]")
    let response = await Axios.post("course_type_admin/admin/course/type/submit", changedData)
    if (response.status === 200) {
      getEmailDetails()
      toast("Successfully submited", {
        type: "success",
        position: "top-center",
        theme: "dark",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
    else {
      toast("Something went wrong", {
        type: "danger",
        position: "top-center",
        theme: "dark",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
    console.log(response, "response")
  }


  console.log(data, updatedData, "onclickSaveddddddddd");
  return (
    <>



      <div class="card height-auto">
        <div className="card-body">
          <div
            className="table-responsive"
            style={{ height: 550, width: '100%', }}
          >
            <div className="d-flex justify-content-end" style={{ marginTop: "25px", marginBottom: "25px" }}>

              <div className="col-md-4 col-xl-4 col-lg-4 col-4 form-group">
                <TextField
                  id="outlined-basic"
                  label="Query Type Name"
                  variant="outlined"
                  value={courseType.course_type_name}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />

              </div>
              <button
                type="button"
                onClick={onClickCourseName}
                style={{ height: "53px" }}
                className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
              >
                Add Query
              </button>
            </div>

            <div>
              {data.length > 0 ? data?.map((item, index) => {
                return (
                  <>
                    <div className="row" style={{ width: "100%" }}>
                      <div className="col-md-4 col-xl-4 col-lg-4 col-12 form-group r-m">
                        <FormControl fullWidth className="regions-field-ali">
                          <InputLabel id="regions-select-helper-label">
                            Query
                          </InputLabel>
                          <Select
                            labelId="regions-select-helper-label"
                            id="regions-select-helper"
                            label="Regions"
                            name="country_name"
                            value={item?.course_type_id}
                            //disabled={price?.email_id?true:false}
                            onChange={(e) =>
                              handleQueryChange(item, index, e.target.value)
                            }
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {queryTypes.map((c) => {
                              return (
                                <MenuItem value={c?.course_type_id}>{c?.course_type_name}</MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </div>

                      <div className="col-md-4 col-xl-4 col-lg-4 col-12 form-group add-in">
                        <TextField
                          error={!!item.error?true:false}
                          helperText={!!item.error&&"please enter valid email id"}
                          id="outlined-basic"
                          label={"Enter Email Id"}
                          variant="outlined"
                          type="text"
                          name="price_value"
                          //disabled={price.email_id ? true : false}
                          value={item.email_id}
                          onChange={(e) => {
                            handleEmailChange(item, index, e.target.value)
                          }}
                        />
                        {data.length > 1 && <button
                          type="submit"
                          className="btn btn-danger ml-2"
                          onClick={() => {
                            handleDeleteChange(item, index);
                          }}
                        >
                          <i className="fas fa-trash-alt"></i>
                        </button>}

                      </div>

                    </div>
                  </>
                );
              }) :
                <div className="row">
                  <div className="col-md-4 col-xl-4 col-lg-4 col-12 form-group r-m">
                    <FormControl fullWidth className="regions-field-ali">
                      <InputLabel id="regions-select-helper-label">
                        Regions
                      </InputLabel>
                      <Select
                        labelId="regions-select-helper-label"
                        id="regions-select-helper"
                        label="Regions"
                        name="country_name"
                        //value={e.target.value}
                        onChange={(e) =>
                          addNewEmail(e.target.value)
                        }
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {queryTypes.map((c) => {
                          return (
                            <MenuItem value={c?.course_type_id}>{c?.course_type_name}</MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-md-4 col-xl-4 col-lg-4 col-12 form-group add-in">
                    <TextField
                      id="outlined-basic"
                      //label={price.email_id ? price.email_id : "Enter Email Id"}
                      variant="outlined"
                      type="text"
                      name="price_value"
                      //disabled={price.email_id ? true : false}
                      //value={`${price.price_value}`}
                      onChange={(e) => {
                        addNewEmail(e.target.value)
                      }}
                    />



                  </div>
                </div>

              }
              <div className="row" style={{ width: "100%" }}>
                <div className="col-md-12 col-xl-12 col-lg-12 col-12 form-group r-m">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      if (data && data?.[data.length - 1]?.email_id) {
                        onEmailAdd();
                      } else {
                        toast("Please fill the details to continue", {
                          type: "warning",
                          position: "top-center",
                          theme: "dark",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                        });
                      }
                    }}
                    type="submit"
                    className="btn-fill-lg bg-blue-dark btn-hover-yellow radius-30"
                  >
                    + Add More Emails
                  </button>
                </div>
              </div>


            </div>

            <div className="d-flex justify-content-end" style={{ position: "", bottom: 0, right: 0 }}>
              <button
                type="button"
                onClick={onSubmit}
                style={{ height: "53px" }}
                className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark"
              >
                Submit
              </button>
            </div>

          </div>
        </div>
      </div>



    </>
  );
};
export default Lables;
