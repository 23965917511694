import React, { useContext, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import logo from "../../assests/img/logo33.png";
import { authContext } from "./../../context/AuthContext";
import { AUTH_TYPES } from "./../../utilities/authTypes";
import { admin, courseadmin, teacher } from "../../../src/utilities/Routs";
import { useHistory } from "react-router-dom";
// import { Redirect, useLocation } from "react-router";

const Sidebar = () => {
  const { authState, dispatch } = useContext(authContext);
  useEffect(() => {
    let role = localStorage.getItem("role");
    if (role === "admin") {
      dispatch({ type: AUTH_TYPES.SET_ROLE, payload: admin });
    } else if (role === "courseadmin") {
      dispatch({ type: AUTH_TYPES.SET_ROLE, payload: courseadmin });
    } else if (role === "teacher") {
      dispatch({ type: AUTH_TYPES.SET_ROLE, payload: teacher });
    }
    //eslint-disable-next-line
  }, []);
  let history = useHistory();

  const handleLogoClick = () => {
    let role = localStorage.getItem("role");
    if (role === "admin") {
      history.push("/Account Admin Dashboard");
    } else if (role === "courseadmin") {
      history.push("/Course Admin Dashboard");
    } else {
      history.push("/Teacher Dashboard");
    }
  };

  return (
    <>
      <div
        style={{
          visibility: !authState.isAuthenticated ? "hidden" : "visible",
        }}
      >
        {/* Sidebar Area Start Here */}
        <div
          className="sidebar-main sidebar-menu-one sidebar-expand-md sidebar-color"
          style={{ height: "100%", minHeight: "100rem" }}
        >
          <div className="mobile-sidebar-header d-md-none">
            <div
              className="header-logo pointer"
              onClick={() => handleLogoClick}
            >
              <img src={logo} alt="logo" />
            </div>
          </div>
          <div className="sidebar-menu-content">
            <ul className="nav nav-sidebar-menu sidebar-toggle-view">
              {authState.sideBarRoutes.map((item, index) => (
                <li
                  key={JSON.stringify(item)}
                  className="nav-item sidebar-nav-item"
                >
                  <Link to={item.path} className="nav-link pointer">
                    <i className={item.icon} />
                    <span>{item.name}</span>
                  </Link>
                  {item.childs.length !== 0 ? (
                    <ul className="nav sub-group-menu">
                      {item.childs.map((subItem, subIndex) => (
                        <li className="nav-item" key={JSON.stringify(subItem)}>
                          <NavLink
                            to={subItem.path}
                            className="nav-link"
                            exact
                            activeClassName="menu-active"
                          >
                            <i className="fas fa-angle-right" />
                            {subItem.name}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  ) : null}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
