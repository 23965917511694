import React from "react";

const Footer = () => {
  return (
    <>
      <footer className="footer-wrap-layout1">
        <div className="copyright">
          © Copyrights{" "}
          <a href="https://rushipeetham.org/" target="_blank" rel="noreferrer">
            Rushividyabhyasam
          </a>{" "}
          {Date().split(" ")[3]}. All rights reserved. Designed by{" "}
          <a href="https://pronteff.com/" target="_blank" rel="noreferrer">
            Pronteff It Solutions
          </a>
        </div>
      </footer>
    </>
  );
};

export default Footer;
