import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import React from 'react'
import AddStudentEvent from './AddStudentEvent'
import Axios from "../../utilities/axios";
import { toast } from 'react-toastify';
import swal from 'sweetalert';

const StudentEventRegisterList = ({
    event_rows,
    studentsDetails
}) => {
console.log(studentsDetails,"studentsDetails")
    const [rows, setRows] = React.useState([])
    const [modelOpen,setModelOpen] = React.useState(false)
    const columns = [
        { field: 'id', headerName: 'ID Number', width: 120, hide: true },
        { field: 'user_name', headerName: 'Name', width: 150 },
        { field: 'email_id', headerName: 'Email Id', width: 180 },
        { field: 'course_name', headerName: 'Course', width: 210 },
        { field: 'event_name', headerName: 'Event Name', width: 210 },
        { field: 'event_date', headerName: 'Date', width: 150 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 150,
            renderCell: (params) => (
                RenderDetailsButton(params, setRows)
            ),
            disableClickEventBubbling: true,
            filterable: false,
        }


    ]
    React.useEffect(()=>{
        callApi()
       
    },[])
    React.useEffect(()=>{
        
        if(studentsDetails.length>0){
            setRows(studentsDetails)
        }
        else{
            setRows([])
        }
    },[studentsDetails])
    const callApi = () => {
        Axios.get(`/events/get_register_students_event`)
            .then((res) => {
                
                setRows(res.data.result)
            })
            .catch((err) => {
                toast("Something went Wrong", {
                    type: "error",
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            });
    }
    return (
        <div style={{ marginTop: "44px" }}>
            <div className="item-title col-md-12 new-btn-sty">
                <h4>Students Event Registered List</h4>
                <button
                    onClick={() => {
                        setModelOpen(true)
                    }}
                    type="submit"
                    className="btn-fill-lg bg-blue-dark btn-hover-yellow add-in-btn"
                >
                    Add people
                </button>
            </div>
            <div
                className="table-responsive"
                style={{ height: 450, width: '100%' }}
            >
                <DataGrid
                    components={{
                        Toolbar: GridToolbar,
                    }}
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                />
            </div>
         { modelOpen &&  <AddStudentEvent modelOpen={modelOpen} setModelOpen={setModelOpen} event_rows={event_rows} setRows={setRows}/>}
        </div>
    )
}

export default StudentEventRegisterList

const RenderDetailsButton = (params, setRows) => {

    const onDelete = (param)=>{
        console.log(param,"prammmmmm")
        swal({
            title: "Are you sure?",
            text: "Once Remove, Student will removed permanently from Event",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
        Axios.get(`/events/delete_student_from_event/${param.row.register_id}`)
        .then((res) => {
           console.log(res,"res.meaasage")
            if (res?.data?.status == 200) {
                toast("Successfully removed", {
                    type: "success",
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                setRows(res?.data?.result)
                

            }
            else{
                toast(res?.data?.message, {
                    type: "error",
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
            
            
        })
        .catch((err) => {
            
            toast("Something went Wrong", {
                type: "error",
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        })
    }})
    }

    return(
        <strong>
            <button
                variant="contained"
                color="primary"
                size="small"
                className="btn btn-danger"
                onClick={() => {
                    onDelete(params)
                }}
            >
                Remove
            </button>
            </strong>
    )
}
