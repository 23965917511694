import React, { useContext } from 'react'
import { authContext } from '../context/AuthContext'
import { useHistory } from 'react-router'
import FormControl from '@mui/material/FormControl'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import Input from '@mui/material/Input'
import Button from '@mui/material/Button'
import OtpInput from 'react-otp-input'
import TextField from '@mui/material/TextField'
import Axios from '../utilities/axios'
import { toast } from 'react-toastify'
import CryptoJS from 'crypto-js'

const Forget = () => {
  const { authState } = useContext(authContext)
  let history = useHistory()
  const [visible, setVisible] = React.useState(true)
  let [userOtp, setUserOtp] = React.useState(0)
  const [dataShow, setDataShow] = React.useState(false)
  const [email_id, setEmail_Id] = React.useState('')
  const [pleasewait, setPleasewait] = React.useState(false)
  const [otpvalid, setOtpvalid] = React.useState(false)
  const [originalOtp, setOriginalOtp] = React.useState('')
  const [values, setValues] = React.useState({
    password: '',
    Cpassword: '',
    showCPassword: false,
    showPassword: false,
  })
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value })
  }
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    })
  }
  const handleClickShowCPassword = () => {
    setValues({
      ...values,
      showCPassword: !values.showCPassword,
    })
  }
  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }
  const handleMouseDownCPassword = (event) => {
    event.preventDefault()
  }
  React.useEffect(() => {
    const user = authState.isAuthenticated
    if (user && user !== 'undefined') {
      history.push('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleOTP = (otp) => {
    setUserOtp(otp)
    if (otp === originalOtp) {
      setVisible(!visible)
    } else {
      setOtpvalid(true)
      setVisible(true)
    }
  }
  const fun = (data) => {
    Axios.post('user_details/forgetPassword_otp_send', data)
      .then((res) => {
        // console.log(res.data, "Response 44444444444");
        if (res.data.message === 'please enter registered email id') {
          toast(res.data.message, {
            type: 'error',
            theme: 'dark',
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        } else {
          toast(`We have sent the OTP to your email`, {
            type: 'success',
            theme: 'dark',
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
          setOriginalOtp(res.data.otp)
          setDataShow(true)
          setPleasewait(false)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const handleNext = () => {
    let data = { email_id: email_id }
    setPleasewait(true)
    fun(data)
  }
  // 1633417616808; 60591479
  const handleSubmit = () => {
    // alert("You have successfully changed your password");
    if (values.password === values.Cpassword) {
      const new_pass = CryptoJS.AES.encrypt(values.password, 'rushi').toString()
      let data = {
        email_id: email_id,
        new_password: new_pass,
      }
      // console.log(data, "Data Sending.....");
      Axios.post('user_details/changepassword_otp', data)
        .then((res) => {
          // console.log(res.data.status, "Response");
          if (res.data.status === 200) {
            toast(res.data.message, {
              type: 'success',
              theme: 'dark',
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
            history.push('/login')
          }
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      toast('Password and Confirm Password should be same', {
        type: 'error',
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }
  const handleResend = () => {
    let data = { email_id: email_id }
    fun(data)
  }
  return (
    <div className="signup-page-wrap">
      <div className="login-page-content">
        <div className="login-box sign-sec">
          <form className="login-form">
            {!dataShow ? (
              <>
                <div className="row">
                  <div className="col-md-12 mg-b-20">
                    <TextField
                      id="outlined-basic"
                      label="Enter Your Email-id"
                      variant="outlined"
                      value={email_id}
                      onChange={(e) => setEmail_Id(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-8 mg-b-20">
                    <button
                      type="button"
                      onClick={handleNext}
                      className="btn-fill-lg bg-blue-dark btn-hover-yellow add-in-btn"
                    >
                      {pleasewait ? 'Please wait..' : `Next`}
                    </button>
                  </div>
                </div>
              </>
            ) : (
              ''
            )}
            <div className="row">
              {dataShow && visible ? (
                <>
                  <center>
                    <p>Enter OTP Here</p>
                  </center>
                  <div className="col-md-12 o-t-p mg-b-10">
                    <OtpInput
                      className="otp__sections"
                      value={userOtp}
                      style={{
                        border: `${!otpvalid}? "" : "2px solid red !important"`,
                      }}
                      placeholder="****"
                      onChange={(userOtp) => handleOTP(userOtp)}
                      numInputs={4}
                      hasErrored={true}
                      separator={
                        <center>
                          <span> - </span>
                        </center>
                      }
                    />
                  </div>
                  <div className="sign-up mg-b-20 pre-link r-send">
                    If you don't receive a code{' '}
                    <span
                      style={{ color: 'red', cursor: 'pointer' }}
                      onClick={handleResend}
                    >
                      Resend
                    </span>
                  </div>
                </>
              ) : (
                ''
              )}
              {!visible ? (
                <>
                  <div className="col-md-12 mg-b-20">
                    <FormControl fullWidth variant="standard">
                      <Input
                        placeholder="New Password"
                        id="standard-adornment-password"
                        type={values.showPassword ? 'text' : 'password'}
                        value={values.password}
                        onChange={handleChange('password')}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {values.showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </div>
                  <div className="col-md-12 mg-b-20">
                    <FormControl fullWidth variant="standard">
                      <Input
                        placeholder="Confirm Password"
                        id="standard-adornment-password"
                        type={values.showCPassword ? 'text' : 'password'}
                        value={values.Cpassword}
                        onChange={handleChange('Cpassword')}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowCPassword}
                              onMouseDown={handleMouseDownCPassword}
                            >
                              {values.showCPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </div>
                  <div className="col-md-12 mg-b-20 o-t-p-btn">
                    <Button
                      onClick={() => handleSubmit()}
                      variant="contained"
                      type="button"
                      disabled={visible}
                    >
                      Submit
                    </Button>
                  </div>
                  <div className="sign-up pre-link">
                    Your password has been updated{' '}
                    <a href="/login">Signin now!</a>
                  </div>
                </>
              ) : (
                ''
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Forget
