import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useHistory, useParams } from "react-router";
import Axios from "../utilities/axios";
import parse from "html-react-parser";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
// import moment from "moment";
import moment from "moment-timezone";
import Glipmsecard from "./commonComponents/Glipmsecard";
// import parse from "html-react-parser";

const Viewcourse = () => {
  let { id } = useParams();
  let history = useHistory();
  const [value, setValue] = React.useState(0);
  const [data, setData] = React.useState(null);
  const [glimpse, setGlimpse] = React.useState(null);
  const [teachers, setTeachers] = React.useState(null);
  const [months, setMonths] = React.useState([]);
  const [selectedMonth, setSelectedMonth] = React.useState("");
  const [type, setType] = React.useState("");
  const [onlyTopics, setOnlyTopics] = React.useState([]);
  const [dummy, setDummy] = React.useState(true);
  const [topics, setTopics] = React.useState([]);
  // const [slotTimes, setSlotTimes] = React.useState([]);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [teacherroute, setTeacherroute] = React.useState(false);
  const handleClick = (index) => {
    if (selectedIndex === index) {
      setSelectedIndex(0);
    } else {
      setSelectedIndex(index);
    }
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  React.useEffect(() => {
    setTeacherroute(history.location.pathname.includes("viewcourse"));
    let data = {
      course_id: id,
      student_id: 0,
    };
    Axios.post(`courses/get_single_course`, data)
      .then((res) => {
        let datacoming = res?.data?.data;
        setData(datacoming && datacoming);
        setType(res?.data?.type && res?.data?.type);
        setGlimpse(datacoming && datacoming?.[1]);
        // setTeachers(datacoming && datacoming?.[2]);
        if (
          !teacherroute &&
          window.localStorage.getItem("role") === "teacher"
        ) {
          let uid = window.localStorage.getItem("uid");
          let arr =
            datacoming && datacoming?.[2].filter((item) => uid == item.user_id);
          // console.log(arr, "Hello Peter !!!!!!!!");
          setTeachers(arr.length > 0 ? arr : []);
        } else {
          setTeachers(
            datacoming && datacoming?.[2].length > 0 ? datacoming?.[2] : []
          );
        }
        // console.log(!teacherroute, "RAVI_____---------");
        // let arr = datacoming && datacoming?.[2][0]?.slot_time?.split(",");
        // setSlotTimes(arr.length > 0 ? arr : []);
        let val = datacoming[3].map((item) => {
          return {
            ...item,
            weekCount: item.total_topics[0].weekCount,
          };
        });
        setTopics(val);
        // console.log("uday", val);
        getOnlySelectedMonth(datacoming && datacoming?.[3]);
        toGetMonths(datacoming && datacoming?.[3]);
        setDummy(!dummy);
      })
      .catch((err) => {
        console.log(err);
      });
    //eslint-disable-next-line
  }, []);
  const getOnlySelectedMonth = (arr) => {
    // let a = months.filter((item) => item.week === selectedMonth);
    // console.log(
    //   months,
    //   "~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~"
    // );
  };
  console.log(teachers, "TEACHERS");

  const toGetMonths = (data) => {
    let yearMonth = [
      ...new Set(
        data.map(
          (val) =>
            moment(val.week.split("/")[0], "DD-MM-YYYY").format("MMMM yyyy") ||
            moment(val.week.split("/")[1], "DD-MM-YYYY").format("MMMM yyyy")
        )
      ),
    ];
    setMonths(yearMonth);
    // console.log("selected months ", yearMonth);
  };

  // const toReturnMoths = (fieldValue, duration) => {
  //   if (!fieldValue && !duration) return [];
  //   let startDate = fieldValue;
  //   let endDate = moment(fieldValue).add(duration, "months");
  //   let numberOfWeaks = weeksBetweenDates(startDate, endDate);
  //   let a = [];
  //   let start = moment(startDate).format("DD-MM-YYYY");
  //   for (let index = 0; index < numberOfWeaks; index++) {
  //     let newDate = moment(start, "DD-MM-YYYY").add(7, "days");
  //     a.push(moment(newDate).format("DD-MM-YYYY"));
  //     start = newDate;
  //   }
  //   return a;
  // };
  // const weeksBetweenDates = (d0, d1, startDay) => {
  //   if (typeof startDay != "number") startDay = 1;
  //   d0 = new Date(d0);
  //   d1 = new Date(d1);
  //   [d0, d1].forEach((d) =>
  //     d.setDate(d.getDate() + ((startDay - d.getDay() - 7) % 7))
  //   );
  //   if (d1 < d0) {
  //     var t = d1;
  //     d1 = d0;
  //     d0 = t;
  //   }
  //   return Math.round((d1 - d0) / 6.048e8) + 1;
  // };
  // let duration = {
  //   semester: 6,
  //   trimister: 3,
  //   quarterly: 3,
  //   yearly: 12,
  // };
  // React.useEffect(() => {
  //   let weaks = toReturnMoths(
  //     data?.[0]?.[0]?.start_date,
  //     duration[data?.[0]?.[0]?.course_duration]
  //   );
  //   // let month = [
  //   //   ...new Set(
  //   //     weaks.map((val) => moment(val, "DD-MM-YYYY").format("MMMM yyyy"))
  //   //   ),
  //   // ];
  //   // setMonths(month);
  //   let startWeak = moment(data?.[0]?.[0]?.start_date).format("DD-MM-YYYY");
  //   let finalWeaks = [];
  //   weaks.map((val, i) => {
  //     finalWeaks.push({
  //       week: `${startWeak}/${val}`,
  //       topics: [
  //         {
  //           month: `${startWeak}/${val}`,
  //           week: `${startWeak}/${val}`,
  //           name: "",
  //           topic: "",
  //           homework: "",
  //           pdf: "",
  //         },
  //       ],
  //     });
  //     startWeak = val;
  //   });
  //   setAllweaks(finalWeaks);
  //   //eslint-disable-next-line
  // }, [data?.[0]?.[0]?.course_duration]);
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));
  const handleCh = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  React.useEffect(() => {
    setDummy(!dummy);
    let a = topics.filter(
      (item) =>
        moment(item.week.split("/")[0], "DD-MM-YYYY").format("MMMM yyyy") ===
        selectedMonth
    );
    setOnlyTopics(a);
    // console.log(a, "~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~", a);
    // eslint-disable-next-line
  }, [selectedMonth]);

  const handleEdit = (e) => {
    e.preventDefault();
    let tole = localStorage.getItem("role");
    if (tole === "admin") {
      history.push(`/editcourse/${id}`);
    } else {
      history.push(`/edit/${id}`);
    }
  };
  return (
    <div>
      <div className="card height-auto">
        <div className="card-body">
          <h4 className="main-h">
            {data?.[0]?.[0]?.course_name?.toUpperCase() || "SomeCourse"}
            {type?.[0]?.type && (
              <h4
                className="main-h"
                style={{ fontSize: "1.5rem", margin: 0, padding: 0 }}
              >{`(${type?.[0]?.type.toUpperCase()})`}</h4>
            )}
          </h4>
          <section className="mg-b-20">
            <div className="row">
              <div className="col-md-8 w-topics w-t-view">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label="Overview" {...a11yProps(0)} />
                    <Tab label="Curriculum" {...a11yProps(1)} />
                    {/* {!teacherroute ? ( */}
                    <Tab label="Instructor" {...a11yProps(2)} />
                    {/* ) : null} */}
                    {/* <Tab label="Reviews" {...a11yProps(3)} /> */}
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                  <div className="view-txt">
                    <h4 className="mg-b-15">
                      What is {data?.[0]?.[0]?.course_name}?
                    </h4>
                    <p className="bhanu">
                      {data?.[0]?.[0] &&
                        parse(
                          JSON.parse(data?.[0]?.[0]?.course_description)
                            .description
                        )}
                    </p>
                  </div>
                  <div className="view-txt">
                    <h4 className="mg-b-15">What we learn ?</h4>
                    <p>
                      {data?.[0]?.[0] &&
                        parse(JSON.parse(data?.[0]?.[0]?.what_we_learn).what)}
                    </p>
                  </div>
                  <div className="view-txt">
                    <h4 className="mg-b-15">Additional Information</h4>
                    <p>
                      {data?.[0]?.[0] &&
                        parse(JSON.parse(data?.[0]?.[0]?.note).note)}
                    </p>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <div className="row">
                    <div className="col-md-5 col-xl-5 col-lg-5 col-12 form-group">
                      <FormControl fullWidth>
                        <InputLabel id="month-select-helper-label">
                          Select Month
                        </InputLabel>
                        <Select
                          labelId="month-select-helper-label"
                          id="month-select-helper"
                          value={`${selectedMonth}`}
                          label="Select Month"
                          onChange={(e) => setSelectedMonth(e?.target?.value)}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {months &&
                            months?.map((val, i) => (
                              <MenuItem value={val} key={i}>
                                {val}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className="row mg-b-10">
                    <div className="col-md-12">
                      {onlyTopics.length > 0 ? (
                        onlyTopics?.map((item, index) => {
                          return (
                            <Card
                              className="w-t-view"
                              style={{
                                marginBottom: "1rem",
                              }}
                            >
                              <CardActions>
                                <h6>{item?.weekCount}</h6>

                                <ExpandMore
                                  expand={index === selectedIndex}
                                  onClick={handleExpandClick}
                                  aria-expanded={expanded}
                                  aria-label="show more"
                                >
                                  <ExpandMoreIcon
                                    onClick={() => handleClick(index)}
                                  />
                                </ExpandMore>
                              </CardActions>
                              <Collapse
                                in={index === selectedIndex}
                                timeout="auto"
                                unmountOnExit
                              >
                                <div className="row cur-lab">
                                  <div className="col-md-6">Topic Name</div>
                                  <div className="col-md-3">Home Work</div>
                                  <div className="col-md-3">Topic Content</div>
                                </div>
                                <CardContent className="chap-main">
                                  {item?.total_topics?.map((topic) => {
                                    return (
                                      <div
                                        key={topic?.topic_id}
                                        className="row chap-sec"
                                      >
                                        <div className="col-md-6">
                                          {topic?.topicName}
                                        </div>
                                        <div className="col-md-3">
                                          {topic?.homework === "true"
                                            ? "Yes"
                                            : "No"}
                                        </div>
                                        <div className="col-md-2 material-icns">
                                          <button className="mr-2">
                                            <a
                                              href={topic?.pdf}
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              <i className="far fa-file-pdf"></i>
                                            </a>
                                          </button>
                                          <button>
                                            <a
                                              href={topic?.video}
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              <i className="fab fa-youtube"></i>
                                            </a>
                                          </button>
                                        </div>
                                        <div className="col-md-1 material-lock">
                                          {/* <i class="fas fa-lock"></i> */}
                                        </div>
                                      </div>
                                    );
                                  })}
                                </CardContent>
                              </Collapse>
                            </Card>
                          );
                        })
                      ) : (
                        <h5 className="text-red">
                          Please Select Month to Show Data
                        </h5>
                      )}
                    </div>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  {teachers ? (
                    teachers.length !== 0 ? (
                      teachers.map((teacher) => {
                        return (
                          <div className="row mg-b-20">
                            <div className="col-md-12">
                              <div className="t-profile">
                                <div className="row">
                                  <div className="col-md-4">
                                    <img
                                      alt="nothing here"
                                      src={
                                        teacher.image ||
                                        "https://via.placeholder.com/150"
                                      }
                                    ></img>
                                    <div className="c-content t-a-s">
                                      <div>Available slots</div>
                                      <div className="c-values a-slots">
                                        {teacher.available_slots}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-8 t-p-txt">
                                    {teacher?.slot_time
                                      ?.split(",")
                                      .map((item) => (
                                        <p className="c-time">
                                          Class Time:{" "}
                                          <span>
                                            {moment(item)
                                              .tz(moment.tz.guess())
                                              .format("dddd hh:mm A z")}
                                          </span>
                                        </p>
                                      ))}
                                    {/* {console.log(slotTimes, "Ravi")} */}
                                    {/* {moment
                                          .tz(
                                            teacher?.slot_time,
                                            "America/Los_Angeles"
                                          )
                                          .clone()
                                          .tz(moment.tz.guess())
                                          .format("dddd hh:mm A z")} */}
                                    <div className="t-slot-sec">
                                      <h4 className="mg-b-10">
                                        {teacher?.fullname || "Teacher Name"}
                                      </h4>
                                    </div>

                                    <p>
                                      {(teacher &&
                                        parse(teacher.description)) ||
                                        "Lorem Ipsum is simply dummy text of the"}
                                    </p>
                                    <h6>Details</h6>
                                    <p>
                                      DOB:{" "}
                                      <span>
                                        {teacher?.dob || "27/FEB/1980"}
                                      </span>
                                    </p>
                                    <p>
                                      Association Duration:{" "}
                                      <span>
                                        {teacher?.associated_from} years
                                      </span>
                                    </p>
                                    <p>
                                      Courses:
                                      <span> {teacher?.courses}</span>
                                    </p>
                                    <div className="mg-t-15 t-contact">
                                      <p>
                                        <i className="fas fa-mobile-alt"></i>
                                        <span>
                                          {teacher.phone_number ||
                                            "040-2713 2550"}
                                        </span>
                                      </p>
                                      <p>
                                        <i className="far fa-envelope t-mail"></i>
                                        <span>
                                          {teacher.email_id ||
                                            "info@rushipeetham.org"}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <h3>No teachers Associated</h3>
                    )
                  ) : (
                    <div id="preloader"></div>
                  )}
                </TabPanel>
                <TabPanel value={value} index={3}></TabPanel>
              </div>
              <div className="col-md-4">
                <div className="c-profile">
                  <img
                    alt="not loaded"
                    src={data?.[0]?.[0]?.course_image}
                  ></img>
                  <div className="c-content">
                    <div>Prices</div>
                    <div className="c-values">
                      {data?.[4].map((item) => {
                        return (
                          <section className="price-sec">
                            {/* {console.log(item)} */}
                            <span className="region-name">
                              {item.country_name} -
                            </span>
                            <span className="c-price">
                              {/* ${data?.[4]?.[0]?.price || "000"} */}
                              {item.price_value} ({item.currency_code})
                            </span>
                          </section>
                        );
                      })}

                      {/* <section className="price-sec">
                        <span className="region-name">America -</span>
                        <span className="c-price">
                          ${data?.[0]?.[0]?.price || "000"}
                        </span>
                      </section>
                      <section className="price-sec">
                        <span className="region-name">Australia -</span>
                        <span className="c-price">
                          ${data?.[0]?.[0]?.price || "000"}
                        </span>
                      </section> */}
                    </div>
                  </div>
                  <div className="c-content">
                    <div>Duration</div>
                    <div className="c-values">
                      {data?.[0]?.[0]?.course_duration || "Trimister"}
                    </div>
                  </div>
                  <div className="c-content">
                    <div>Start date</div>
                    <div className="c-values">
                      {data?.[0]?.[0]?.start_date || "01/01/2022"}
                    </div>
                  </div>
                  {/* <div className="c-content">
                    <div>Teachers</div>
                    <div className="c-values">
                      <span>{data?.[2]?.[0]?.fullname || "John Deo"}</span>
                    </div>
                  </div> */}
                  {!teacherroute ? (
                    <div className="c-content">
                      <div>Teachers count</div>
                      <div className="c-values">
                        {data?.[2]?.length || "00"}
                      </div>
                    </div>
                  ) : null}
                  <div className="c-content">
                    <div>Students Enrolled</div>
                    <div className="c-values">
                      {data?.[0]?.[0]?.student_enrolled || "00"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <section>
        <h3 className="mg-b-15">A glimpse of the topics covered</h3>
        <div className="row">
          {/* <div class="col-md-4"> */}
          {/* {console.log(glimpse)} */}
          {glimpse ? (
            glimpse.length === 0 ? (
              <h6 style={{ marginLeft: "2rem" }}>
                <b>No Glimpse Added</b>
              </h6>
            ) : (
              glimpse?.map((item, index) => (
                <div key={item.glimpse_id} className="col-md-4">
                  <Accordion
                    expanded={expanded === `panel${index + 1}`}
                    onChange={handleCh(`panel${index + 1}`)}
                  >
                    <AccordionSummary>
                      <Glipmsecard
                        src={item.glimpse_image}
                        name={item.glimpse_name}
                        number={item.glimpse_description}
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>{item.glimpse_description}</Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              ))
            )
          ) : (
            <div id="preloader"></div>
          )}
          {/* </div> */}
        </div>
      </section>
      <div className="row">
        {/* <div className="col-md-"></div> */}
        <div className="col-md-3">
          {!teacherroute ? (
            <button
              type="submit"
              className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark mt-4"
              onClick={handleEdit}
            >
              <i className="fas fa-edit"></i> Edit Course
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Viewcourse;

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
