import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import GlimpseModel from "./GlimpsModel";
import Glipmsecard from "../commonComponents/Glipmsecard";
import dummy_image from "../../assests/img/figure/user3.jpg";
import { uploadFile } from "react-s3";

const config = {
  bucketName: process.env.REACT_APP_S3_BUCKET,
  region: process.env.REACT_APP_REGION,
  dirName: "folder",
  accessKeyId: process.env.REACT_APP_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
};

const Glimpse = ({
  deletedarray,
  setDeletedarray,
  glimpsArray,
  setGlimpsArray,
}) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [dummy, setdummy] = useState(true);
  // const [selectedFile, setSelectedFile] = React.useState(null);
  const [editInx, setEditInx] = useState(null);
  const [glimps, setGlimps] = useState({
    glimpse_name: "",
    glimpse_description: "",
    glimpse_file: "",
    glimpse_image: dummy_image,
  });

  //glips change:--
  const handleChangeGlimps = (e) => {
    if (e?.target?.value?.includes("fakepath")) {
      callAfun(e);
    } else {
      setGlimps((ev) => ({
        ...ev,
        [e.target.name]: e.target.value,
        state: "ADD",
      }));
    }
  };
  const callAfun = async (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        handleUpload(e.target.files[0]);
        setdummy(!dummy);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const addGlimps = (e) => {
    let data = glimpsArray;
    data.push(glimps);
    setGlimpsArray(data);
    setGlimps({
      glimpse_name: "",
      glimpse_description: "",
      glimpse_file: "",
      glimpse_image: dummy_image,
    });
    toggle();
  };

  const resetGlimps = () => {
    setGlimps({
      glimpse_name: "",
      glimpse_description: "",
      glimpse_file: "",
      glimpse_image: dummy_image,
    });
  };
  const deleteGlimps = (index) => {
    let data = glimpsArray;
    let deleted = glimpsArray[index];
    if (deleted.glimpse_id) {
      delete deleted.glimpse_name;
      delete deleted.glimpse_description;
      delete deleted.glimpse_image;
      glimpsArray[index].state = "REMOVE";
      let l = [...deletedarray];
      l.push(deleted);
      setDeletedarray(l);
    }
    data.splice(index, 1);
    setGlimpsArray(data);
    setdummy(!dummy);
  };
  const onEdit = (item, indx) => {
    toggle();
    setEditInx(indx);
    setGlimps(item);
  };

  const updateGlimps = () => {
    let data = glimpsArray;
    data.splice(editInx, 1, glimps);
    if (glimpsArray[editInx].glimpse_id) {
      glimpsArray[editInx].state = "EDIT";
    }
    setGlimpsArray(data);
    setEditInx(null);
    resetGlimps();
    setdummy(!dummy);
    toggle();
  };

  const [expanded, setExpanded] = React.useState(false);
  const handleCh = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleUpload = async (file) => {
    await uploadFile(file, config)
      .then((data) => {
        if (data?.result?.ok) {
          setGlimps((ev) => ({
            ...ev,
            glimpse_image: data?.location,
          }));
          //  handleSubmit(data.location);
        } else {
          alert("error");
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <section className="mg-b-20">
      <div className="row gutters-8">
        <div className="col-12 mg-b-10 glipmse">
          <button
            type="button"
            onClick={toggle}
            className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark radius-30"
          >
            + Add More Glimpse
          </button>
        </div>
      </div>
      <div className="row mg-b-20">
        {glimpsArray.length > 0
          ? glimpsArray.map((item, index) => (
              <div key={index} className="col-md-4 mg-b-20">
                <span className="g-actions">
                  <i
                    className="fas fa-pencil-alt"
                    onClick={() => onEdit(item, index)}
                  ></i>
                  <i
                    className="fas fa-times pointer del-ac"
                    onClick={() => deleteGlimps(index)}
                  ></i>
                </span>
                <Accordion
                  expanded={expanded === `panel${index + 1}`}
                  onChange={handleCh(`panel${index + 1}`)}
                >
                  <AccordionSummary>
                    <Glipmsecard
                      src={item.glimpse_image}
                      name={item.glimpse_name}
                      number={item.glimpse_description}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{item?.glimpse_description}</Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            ))
          : " "}
        <GlimpseModel
          modal={modal}
          toggle={toggle}
          glimps={glimps}
          handleChangeGlimps={handleChangeGlimps}
          addGlimps={addGlimps}
          resetGlimps={resetGlimps}
          editInx={editInx}
          updateGlimps={updateGlimps}
        />
      </div>
    </section>
  );
};
export default Glimpse;
